import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import LogoAppartaPay from '../../icons/logo';
import ProfileRound from '../../icons/profile-round';
import { useLocation } from 'react-router-dom';
import { ROUTES_ALIAS } from '../../utils/constants';
import BackIcon from '../../icons/back';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/selectors/app-selectors';

function NavBar(props: any) {
    const location = useLocation();
    const navigate = useNavigate();

    const [pathName, setPathName] = useState<string | null>(null);
    const selector = useAppSelector((state) => state);

    const establishmentBranch =
        selector.rootReducer.establishment_branch.establishment_branch;

    useEffect(() => {
        if (
            location.pathname === '/transactions-details' ||
            location.pathname === '/add-credit-card'
        ) {
            setPathName(ROUTES_ALIAS[location.pathname]);
        } else {
            setPathName(null);
        }

        window.scrollTo(0, 0);
    }, [location.pathname]);

    const theme = useTheme();

    return (
        <div className="sticky top-0 bg-purple-main z-30">
            <div className="flex flex-row h-[54px] items-end pb-3 mx-5">
                {!pathName && (
                    <>
                        <div className="basis-2/4">
                            <LogoAppartaPay />
                        </div>
                        <div className="basis-2/4 flex justify-end">
                            <div
                                className="content-end"
                                onClick={() =>
                                    navigate('/transactions-details')
                                }
                            >
                                <ProfileRound />
                            </div>
                        </div>
                    </>
                )}

                {pathName && (
                    <>
                        <div
                            className="basis-2/4 text-left"
                            onClick={() => {
                                // navigate(-1);
                                navigate(
                                    `/apparta?branchId=${establishmentBranch.id}`
                                );
                            }}
                        >
                            <BackIcon />
                        </div>
                        <div className="basis-2/4 text-white min-w-[200px] font-bold">
                            {pathName}
                        </div>
                        <div className="basis-2/4  flex  justify-end">
                            <div
                                className="content-end"
                                onClick={() =>
                                    navigate('/transactions-details')
                                }
                            >
                                <ProfileRound />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default NavBar;
