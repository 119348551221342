import React from 'react';
import numeral from 'numeral';
import Ellipse from '../icons/ellipse';
import { useAppSelector } from '../redux/selectors/app-selectors';

type amountToPaidType = {
    amount?: number;
    amountFromAppartaPay?: number;
    amountFromBonus?: number;
    amountCashback?: number;
};

const ButtonMain = ({
    amount,
    amountFromAppartaPay,
    amountCashback,
    amountFromBonus,
}: amountToPaidType) => {
    const selector = useAppSelector((state) => state);

    const user = selector.rootReducer.auth.user;
    const formatAmount = ({ amount }: { amount?: number }) =>
        numeral(amount).format('$0,0');

    return (
        <div className="w-full text-start mt-4">
            <Ellipse transform={'rotate(180, 0, 0)'} />
            <div className="flex flex-col bg-ellipse-bg w-full pb-20 px-6">
                <div className="text-start mt-3">Resumen de tu cuenta</div>

                <div>
                    {amountCashback && (
                        <div className="flex flex-row justify-between text-start">
                            <div>Cashback</div>
                            <div>
                                {formatAmount({ amount: amountCashback })}
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row justify-between text-start mt-2">
                        <div>Monto a pagar</div>
                        <div>{formatAmount({ amount: amount })}</div>
                    </div>
                    {amountFromBonus && (
                        <div className="flex flex-row justify-between text-start mt-4 text-green-main">
                            <div>Descuento por bono</div>
                            <div>
                                {formatAmount({ amount: amountFromBonus })}
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row justify-between text-start">
                        <div>Cobro en Apparta pay</div>
                        <div>
                            {formatAmount({ amount: amountFromAppartaPay })}
                        </div>
                    </div>

                    <div className="flex flex-row justify-between text-start mt-4">
                        <div>Cobro en segundo metodo</div>
                        <div>
                            {formatAmount({
                                amount:
                                    (amount || 0) > (amountFromAppartaPay || 0)
                                        ? (amount || 0) -
                                          (amountFromAppartaPay || 0)
                                        : 0,
                            })}
                        </div>
                    </div>

                    {user && user.test_user === 'true' && (
                        <>
                            <div className="mt-10 text-purple-main">
                                Esto es de pruebas
                            </div>

                            <div className="flex flex-row justify-between text-start mt-2">
                                <div>Monto a pagar con appartapay</div>
                                <div>
                                    {formatAmount({
                                        amount: amountFromAppartaPay,
                                    })}
                                </div>
                            </div>

                            <div className="flex flex-row justify-between text-start mt-2">
                                <div>Monto a pagar metodo de pago</div>
                                <div>
                                    {formatAmount({
                                        amount:
                                            (amount || 0) >
                                            (amountFromAppartaPay || 0)
                                                ? (amount || 0) -
                                                  (amountFromAppartaPay || 0)
                                                : 0,
                                    })}
                                </div>
                            </div>

                            <div className="flex flex-row justify-between text-start mt-2">
                                <div>Cashback obtenido</div>
                                <div>
                                    {formatAmount({
                                        amount: amountCashback,
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ButtonMain;
