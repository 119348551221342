import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    establishmentBranchService,
    configurationService,
    paymentsService,
    creditCardService,
    walletUserService,
} from '../api/services';
import { actionWalletUser } from '../redux/actions/wallet-user';
import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import EstablishmentLogo from '../icons/establishment-logo';
import WelcomeTextEllipse from '../components/commons/welcome-text-ellipse';
import QuestionIcon from '../components/commons/question-icon';
import TextFieldMain from '../components/commons/text-field-main';
import Line from '../components/commons/line';
import CardPaymentMethod from '../components/commons/card-payment-method';
import Footer from '../components/commons/footer';
import { actionEstablishment } from '../redux/actions/establihsment-branch';
import { actionConfigurations } from '../redux/actions/configurations';
import { actionAlertNotification } from '../redux/actions/alert-notifications';
import { S3_ROOT_PATH } from '../utils/constants';
import { useAppSelector } from '../redux/selectors/app-selectors';
import { EstablishmentBranchInterface } from '../interfaces/establishment-branch';
import { paymentsMethodStatus } from '../interfaces/configuration';
import { actionPaymentMethodSelected } from '../redux/actions/payment-method-selected';
import AmountToPaid from '../components/amount-to-paid';
import { useNavigate, useLocation } from 'react-router-dom';
import SlideDialog from '../components/modals/slide-dialog';
import PaymentWithNequiForm from '../components/forms/payment-nequi';
import { creditCardRoutes } from '../routes/index';
import { actionsCreditCards } from '../redux/actions/credit-card';
import CardCreditCard from '../components/commons/card-credit-card';
import { creditCardInterface } from '../interfaces/credit-cards';
import { useSearchParams } from 'react-router-dom';
import PlusIcon from '../icons/plus';

function Checkout(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const selector = useAppSelector((state) => state);
    const params = useParams();
    const RESTAURANT_SLUG = location.pathname;
    const NEQUI = 'nequi';
    const BANCOLOMBIA = 'bancolombia';
    const WALLET = 'wallet';
    const CREDIT_CARD = 'credit_card';
    const [isAuth, setIsAuth] = useState(selector.rootReducer.auth.isAuth);

    const user = selector.rootReducer.auth.user;

    const BRANCH_ID = searchParams.get('branchId');
    const paymentMethodSelected =
        selector.rootReducer.paymentMethodSelected.payment_method_selected;
    const paymentMethodSelectedAditionalData =
        selector.rootReducer.paymentMethodSelected
            .payment_method_selected_additional_data;

    const walletUser = selector.rootReducer.walletUser.wallet_user;
    const creditCards = selector.rootReducer.creditCards.credit_cards || [];
    const [configurations, setConfigurations] = useState<any>([]);
    const [statusNequi, setStatusNequi] = useState<boolean>(true);
    const [statusCreditCard, setStatusCredictCard] = useState<boolean>(true);
    const [statusPse, setStatusPse] = useState<boolean>(true);
    const [amountNet, setAmountNet] = useState<number>();
    const [paymentWithWallet, setPaymentWithWallet] = useState<boolean | null>(
        false
    );
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
    const [wasChangePaymentMethod, setWasChangePaymentMethod] =
        useState<boolean>(false);

    const [enabledButtonPaid, setEnableButtonPaid] = useState<boolean>(false);

    const [walletBalance, setWalletBalance] = useState<number>(0);

    const [amountToPaidWithWallet, setAmountToPaidWithWallet] =
        useState<number>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [children, setChildren] = useState<ReactNode>();
    const [totalAmount, setTotalAmount] = useState<number>();

    const [statusBancolombiaTransfer, setStatusBancolombiaTransfer] =
        useState<boolean>(true);

    const [establismentBranch, setEstablishmentBranch] =
        useState<EstablishmentBranchInterface>();

    const ID_CONFIGURATION_NEQUI_STATUS = 63;
    const ID_CONFIGURATION_BANCOLOMBIA_TRANSFER_STATUS = 64;
    const ID_CREDIT_CARD_STATUS = 62;
    const ID_PSE_STATUS = 61;

    const calculateAmountForPaidWithWallet = ({
        amountNet,
        paymentWithWallet,
    }: any) => {
        if (!paymentWithWallet) {
            setAmountToPaidWithWallet(0);
        }

        if (amountNet > walletBalance && paymentWithWallet) {
            setAmountToPaidWithWallet(walletBalance);
        }

        if (paymentWithWallet && amountNet < walletBalance) {
            setAmountToPaidWithWallet(amountNet);
        }
    };

    const getEstablishmentData = ({ id }: { id?: string | null }) => {
        if (id)
            establishmentBranchService
                .get(id)
                .then((it: EstablishmentBranchInterface) => {
                    setEstablishmentBranch(it);
                    dispatch(
                        actionEstablishment({
                            TYPE: 'SET_ESTABLISHMENT_BRANCH',
                            params: { ...it },
                        })
                    );
                });
    };

    const changePaymentMethod = () => {
        dispatch(
            actionPaymentMethodSelected({
                TYPE: 'SET_PAYMENT_METHOD_SELECTED',
                params: {
                    payment_method_selected: null,
                    credit_card_id: null,
                },
            })
        );
    };

    const getWallet = () => {
        walletUserService.find({}).then((it: any) => {
            const { data } = it;
            dispatch(
                actionWalletUser({
                    TYPE: 'SET_WALLET_USER',
                    params: { ...data[0] },
                })
            );
        });
    };

    const setPaymentMethod = ({
        payment_method_selected,
        credit_card_id,
    }: {
        payment_method_selected: string | null;
        credit_card_id?: string;
    }) => {
        setWasChangePaymentMethod(true);
        dispatch(
            actionPaymentMethodSelected({
                TYPE: 'SET_PAYMENT_METHOD_SELECTED',
                params: {
                    payment_method_selected: payment_method_selected,
                    credit_card_id: credit_card_id,
                },
            })
        );
    };

    const sendPayment = () => {
        if (!amountNet) {
            alert('Llena los datos');
        }

        if (!paymentMethodSelected?.payment_method_selected) {
            if ((amountNet || 0) > (amountToPaidWithWallet || 0)) {
                alert(
                    `Debes seleccioanar otro metodo de pago para pagar ${
                        (amountNet || 0) - (amountToPaidWithWallet || 0)
                    }`
                );
            }
        }

        const paymentPayload: any = {
            amount_net: amountNet ? +amountNet : 0,
            amount_from_wallet: amountToPaidWithWallet,
            payment_gateway: paymentMethodSelected?.payment_method_selected
                ? paymentMethodSelected.payment_method_selected
                : 'wallet',
            type: 'payment',
            from_wallet_users_id: walletUser.id,
            to_wallet_establishment_id:
                establismentBranch?.wallet_establishment.id,
            data_payment_gateway: {
                phone_number: null,
            },
        };

        if (paymentMethodSelected?.payment_method_selected === BANCOLOMBIA) {
            delete paymentPayload?.data_payment_gateway;
        }

        if (paymentMethodSelected?.payment_method_selected === NEQUI) {
            paymentPayload.data_payment_gateway.phone_number =
                paymentMethodSelectedAditionalData?.phone_number;
        }

        if (amountNet) {
            setIsPaymentLoading(true);
            paymentsService
                .create(paymentPayload)
                .then((payment: any) => {
                    if (
                        payment.payment_gateway === NEQUI ||
                        payment.payment_gateway === WALLET
                    ) {
                        dispatch(
                            actionAlertNotification({
                                TYPE: 'SET_ALERT_NOTIFICATIONS',
                                params: {
                                    open: true,
                                    description:
                                        payment.payment_gateway === NEQUI
                                            ? 'Confirma tu pago en nequi'
                                            : 'Transacción exitosa',
                                    severity: 'info',
                                },
                            })
                        );

                        getWallet();

                        setTimeout(() => {
                            navigate(
                                `../transactions-details?paymentId=${payment.id}`
                            );
                        }, 2000);
                    } else if (payment.payment_gateway === BANCOLOMBIA) {
                        window.location.href = `${payment?.payment?.link?.extra?.async_payment_url}`;
                    } else if (payment.payment_gateway === CREDIT_CARD) {
                        dispatch(
                            actionAlertNotification({
                                TYPE: 'SET_ALERT_NOTIFICATIONS',
                                params: {
                                    open: true,
                                    description: 'Transacción exitosa',
                                    severity: 'info',
                                },
                            })
                        );
                        setTimeout(() => {
                            navigate(
                                `./transactions-details?paymentId=${payment.id}`
                            );
                        });
                    }
                })
                .catch((payment: any) => {
                    dispatch(
                        actionAlertNotification({
                            TYPE: 'SET_ALERT_NOTIFICATIONS',
                            params: {
                                open: true,
                                description: payment.data?.error?.messages[0],
                            },
                        })
                    );
                    setIsPaymentLoading(false);
                });
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const verifyPaymentsStatus = ({
        config,
    }: {
        config: { id: number; status: string };
    }) => {
        setStatusNequi(
            config.id === ID_CONFIGURATION_NEQUI_STATUS &&
                config.status === 'active'
        );
        setStatusPse(config.id === ID_PSE_STATUS && config.status === 'active');
        setStatusCredictCard(
            config.id === ID_CREDIT_CARD_STATUS && config.status === 'active'
        );
        setStatusBancolombiaTransfer(
            config.id === ID_CONFIGURATION_BANCOLOMBIA_TRANSFER_STATUS &&
                config.status === 'active'
        );
    };

    const enabledButtonToPaid = ({ amountNet }: any) => {
        if (!amountNet || amountNet <= 0) {
            setEnableButtonPaid(false);
            console.log('11111');
        }

        if (
            !paymentMethodSelected?.payment_method_selected &&
            amountNet > walletBalance &&
            paymentWithWallet
        ) {
            console.log('33333');
            setEnableButtonPaid(false);
        }

        if (
            !paymentMethodSelected?.payment_method_selected &&
            !paymentWithWallet
        ) {
            console.log('44444');
            setEnableButtonPaid(false);
        }

        if (
            !paymentMethodSelected?.payment_method_selected &&
            paymentWithWallet &&
            amountNet &&
            amountNet <= walletBalance
        ) {
            console.log('66666');
            setEnableButtonPaid(true);
        }

        if (paymentMethodSelected?.payment_method_selected) {
            console.log('7777');
            setEnableButtonPaid(true);
        }
    };

    const getConfigurations = ({
        ids,
    }: {
        ids: [number, number, number, number];
    }) => {
        configurationService
            .find({ query: { id: { $in: ids } } })
            .then((it: { data: [any] }) => {
                setConfigurations(it.data);
                actionConfigurations({
                    TYPE: 'SET_CONFIGURATIONS',
                    params: it.data,
                });
            });
    };

    const getCreditCards = () => {
        creditCardService.find({}).then((it: { data: [any] }) => {
            dispatch(
                actionsCreditCards({
                    type: 'SET_CREDIT_CARDS',
                    params: it.data,
                })
            );
        });
    };

    const updateCreditCard = (creditCardId: any) => {
        const newCreditsCards = creditCards.map(
            (creditCard: creditCardInterface) => ({
                ...creditCard,
                default: creditCard.id == creditCardId ? 'true' : 'false',
            })
        );

        dispatch(
            actionsCreditCards({
                type: 'SET_CREDIT_CARDS',
                params: newCreditsCards,
            })
        );

        setPaymentMethod({
            payment_method_selected: CREDIT_CARD,
            credit_card_id: creditCardId.id,
        });

        creditCardService
            .patch(creditCardId, { default: 'true' })
            .then((creditCardDefault: any) => {
                // getCreditCards();
                // setPaymentMethod({
                //     payment_method_selected: CREDIT_CARD,
                //     credit_card_id: creditCardDefault.id,
                // });
            });
    };

    useEffect(() => {
        calculateAmountForPaidWithWallet({
            amountNet,
            amountToPaidWithWallet,
            paymentWithWallet,
        });
    }, [paymentWithWallet, amountNet]);

    useEffect(() => {
        Promise.all([
            getEstablishmentData({ id: BRANCH_ID }),
            getConfigurations({
                ids: [
                    ID_CONFIGURATION_NEQUI_STATUS,
                    ID_CONFIGURATION_BANCOLOMBIA_TRANSFER_STATUS,
                    ID_CREDIT_CARD_STATUS,
                    ID_PSE_STATUS,
                ],
            }),
            getCreditCards(),
        ]);
    }, [BRANCH_ID]);

    useEffect(() => {
        getCreditCards();
    }, [isAuth]);

    useEffect(() => {
        if (paymentMethodSelected?.payment_method_selected)
            if (
                paymentMethodSelected?.payment_method_selected === 'nequi' &&
                !paymentMethodSelectedAditionalData?.phone_number
            ) {
                setOpenModal(true);
            }
    }, [paymentMethodSelected]);

    useEffect(() => {
        setPaymentWithWallet(true);
        setWalletBalance(walletUser.balance);
    }, [walletUser.balance, isAuth]);

    useEffect(() => {
        const amount =
            (amountToPaidWithWallet ? +amountToPaidWithWallet : 0) >
            (amountNet || 0)
                ? 0
                : (amountNet ? +amountNet : 0) - (amountToPaidWithWallet || 0);

        setTotalAmount(amount);
    }, [amountNet, amountToPaidWithWallet, paymentMethodSelected]);

    useEffect(() => {
        setIsAuth(selector.rootReducer.auth.isAuth);
    }, [selector.rootReducer.auth.isAuth]);

    useEffect(() => {
        enabledButtonToPaid({ amountNet });
    }, [
        amountNet,
        paymentMethodSelected?.payment_method_selected,
        paymentWithWallet,
    ]);

    const setDefaultCreditCard = ({ creditCards }: any) => {
        if (
            creditCards.length &&
            (!paymentMethodSelected ||
                paymentMethodSelected?.payment_method_selected === CREDIT_CARD)
        ) {
            const creditCarsDefault = creditCards.find(
                (creditCard: creditCardInterface) =>
                    creditCard.default === 'true' &&
                    creditCard.status === 'verified'
            );
            if (creditCarsDefault)
                setPaymentMethod({
                    payment_method_selected: CREDIT_CARD,
                    credit_card_id: creditCarsDefault.id,
                });
        }
    };

    useEffect(() => {
        setDefaultCreditCard({ creditCards });
    }, [paymentMethodSelected?.payment_method_selected, creditCards]);

    return (
        <div className="">
            <WelcomeTextEllipse />
            <div id="container" className="px-6">
                <div className="flex flex-row text-start items-center align-baseline">
                    <EstablishmentLogo
                        logoPath={`${S3_ROOT_PATH}/${establismentBranch?.establishment.logo_image_gallery_path}`}
                    />
                    <div className="ml-6">
                        <div>{establismentBranch?.establishment.name}</div>
                        <div className="text-xs">
                            {establismentBranch?.address}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-6 items-start w-full">
                    <div>¿Cuanto vas a pagar?</div>
                    <div className="text-xs mt-1">
                        Ingresa aquí el valor total de tu cuenta a pagar
                    </div>
                    <div className="w-full mt-3">
                        <TextFieldMain
                            onChange={(e) =>
                                setAmountNet(Number(e.target.value))
                            }
                            name={'amount_net'}
                            placeHolder={'Escribe el monto a pagar'}
                            type={'text'}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <Line />
                </div>
                <div className="flex flex-row items-center mt-4">
                    <div className="ml-6 w-full flex items-center">
                        <div>¿Cómo quieres pagar?</div>
                        <div className="ml-6 mt-1">
                            <QuestionIcon />
                        </div>
                    </div>
                    {!!isAuth && (
                        <div
                            className="ml-6"
                            onClick={() => navigate('../add-credit-card')}
                        >
                            <PlusIcon />
                        </div>
                    )}
                </div>
                <div className="flex flex-col items-center mt-6">
                    <div className="w-full">
                        <div
                            onClick={() =>
                                !isAuth
                                    ? navigate(
                                          `${location.pathname}?Authentication=false`
                                      )
                                    : () => {}
                            }
                        >
                            <CardPaymentMethod
                                type="appartaPay"
                                class_text_color={'text-black'}
                                bg="bg-white"
                                primary_text="appartaPay"
                                second_text={`${
                                    !isAuth
                                        ? 'Ingresa con tu cuenta de apparta pay'
                                        : ''
                                }`}
                                haveSwitch={isAuth && true}
                                allow_payments_with_credit_wallet={true}
                                amount={walletUser.balance}
                                setPaymentWithWallet={(e) =>
                                    setPaymentWithWallet
                                        ? setPaymentWithWallet(e)
                                        : () => {}
                                }
                            />
                        </div>
                    </div>
                    {paymentMethodSelected?.payment_method_selected &&
                        paymentMethodSelected?.payment_method_selected !==
                            CREDIT_CARD && (
                            <div className="flex justify-between w-full mt-4">
                                <div className="text-gray-500 text-sm">
                                    Billetera seleccionada
                                </div>
                                <div
                                    onClick={() => changePaymentMethod()}
                                    className="text-sm text-purple-main underline underline-offset-1"
                                >
                                    Cambiar
                                </div>
                            </div>
                        )}
                    {!!(
                        (paymentMethodSelected?.payment_method_selected &&
                            paymentMethodSelected?.payment_method_selected ===
                                CREDIT_CARD &&
                            creditCards.length) ||
                        (creditCards.length &&
                            !paymentMethodSelected?.payment_method_selected)
                    ) && (
                        <div className="w-full my-3 text-gray-500">
                            {!!(
                                !paymentMethodSelected?.payment_method_selected &&
                                paymentMethodSelected?.payment_method_selected !==
                                    CREDIT_CARD
                            ) && <div className="mt-1">Pagar con tarjeta</div>}
                            <div className="mt-2">
                                {!!(
                                    paymentMethodSelected?.payment_method_selected &&
                                    paymentMethodSelected?.payment_method_selected ===
                                        CREDIT_CARD
                                ) && (
                                    <div className="flex justify-between w-full mt-2 mb-4">
                                        <div className="text-gray-500 text-sm">
                                            Tarjeta de credito seleccionada
                                        </div>
                                        <div
                                            onClick={() =>
                                                changePaymentMethod()
                                            }
                                            className="text-sm text-purple-main underline underline-offset-1"
                                        >
                                            Cambiar
                                        </div>
                                    </div>
                                )}
                                {creditCards.map((creditCard: any) => (
                                    <div>
                                        {paymentMethodSelected?.payment_method_selected &&
                                            paymentMethodSelected.payment_method_selected ===
                                                CREDIT_CARD &&
                                            creditCard.id ===
                                                paymentMethodSelected.credit_card_id && (
                                                <>
                                                    <CardCreditCard
                                                        creditCardId={
                                                            creditCard.id
                                                        }
                                                        brand={creditCard.brand}
                                                        masked_number={
                                                            creditCard.masked_number
                                                        }
                                                        checked={
                                                            paymentMethodSelected?.payment_method_selected ===
                                                                'credit_card' &&
                                                            creditCard.default ===
                                                                'true'
                                                        }
                                                        onChange={
                                                            updateCreditCard
                                                        }
                                                    />
                                                    <div className="mt-3"></div>
                                                </>
                                            )}
                                    </div>
                                ))}

                                {!paymentMethodSelected?.payment_method_selected &&
                                    creditCards.map((creditCard: any) => (
                                        <div>
                                            <>
                                                <CardCreditCard
                                                    creditCardId={creditCard.id}
                                                    brand={creditCard.brand}
                                                    masked_number={
                                                        creditCard.masked_number
                                                    }
                                                    checked={
                                                        paymentMethodSelected?.payment_method_selected ===
                                                            'credit_card' &&
                                                        creditCard.default ===
                                                            'true'
                                                    }
                                                    onChange={updateCreditCard}
                                                />
                                                <div className="mt-3"></div>
                                            </>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {statusCreditCard &&
                        !creditCards.length &&
                        (!paymentMethodSelected?.payment_method_selected ||
                            paymentMethodSelected.payment_method_selected ===
                                CREDIT_CARD) && (
                            <div
                                onClick={() => navigate('../add-credit-card')}
                                className="w-full"
                            >
                                <div className="mt-3 w-full">
                                    <CardPaymentMethod
                                        type="addCreditCard"
                                        class_text_color={'text-black'}
                                        bg="bg-white"
                                        primary_text="Crédito o Débito (con CVV)"
                                        arrowIcon={true}
                                    />
                                </div>
                            </div>
                        )}
                    {!paymentMethodSelected?.payment_method_selected && (
                        <div className="w-full my-1 text-gray-500">
                            O usar una billetera
                        </div>
                    )}
                    {statusNequi &&
                        (!paymentMethodSelected?.payment_method_selected ||
                            paymentMethodSelected.payment_method_selected ===
                                NEQUI) && (
                            <div
                                className="mt-3 w-full"
                                onClick={() => {
                                    setPaymentMethod({
                                        payment_method_selected: 'nequi',
                                        credit_card_id: undefined,
                                    });
                                    setChildren(
                                        <PaymentWithNequiForm
                                            handleClose={() => handleClose()}
                                        />
                                    );
                                }}
                            >
                                <CardPaymentMethod
                                    type="nequi"
                                    class_text_color={'text-white'}
                                    bg="bg-nequi-color"
                                    primary_text="Nequi"
                                />
                            </div>
                        )}
                    {statusBancolombiaTransfer &&
                        (!paymentMethodSelected?.payment_method_selected ||
                            paymentMethodSelected.payment_method_selected ===
                                BANCOLOMBIA) && (
                            <div
                                className="mt-3 w-full"
                                onClick={() =>
                                    setPaymentMethod({
                                        payment_method_selected: 'bancolombia',
                                        credit_card_id: undefined,
                                    })
                                }
                            >
                                <CardPaymentMethod
                                    type="bancolombiaTransfer"
                                    class_text_color={'text-black'}
                                    bg="bg-bancolombia-color"
                                    primary_text="Bancolombia"
                                />
                            </div>
                        )}
                </div>
            </div>
            <div>
                <AmountToPaid
                    amount={amountNet ? +amountNet : 0}
                    amountFromAppartaPay={amountToPaidWithWallet}
                />
            </div>
            <div>
                <Footer
                    totalAmount={totalAmount}
                    sendPayment={sendPayment}
                    isLoading={isPaymentLoading}
                    enableButtonPaid={enabledButtonPaid}
                />
            </div>
            <SlideDialog
                children={children}
                open={openModal}
                setOpenModal={setOpenModal}
            />
        </div>
    );
}

export default Checkout;
