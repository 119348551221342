import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            id="Componente_202_4"
            data-name="Componente 202 – 4"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <circle
                id="Elipse_60"
                data-name="Elipse 60"
                cx="17"
                cy="17"
                r="17"
                fill="rgba(228,228,228,0.4)"
            />
            <path
                id="Trazado_532"
                data-name="Trazado 532"
                d="M5.533,0a.617.617,0,0,0-.424.187L.181,5.114a.617.617,0,0,0,0,.872l4.928,4.927a.617.617,0,1,0,.872-.872L1.489,5.55,5.981,1.059A.617.617,0,0,0,5.533,0Z"
                transform="translate(13.5 11.5)"
                fill="#fff"
                stroke="#fff"
                stroke-width="1"
            />
        </svg>
    );
};

export default Verified;
