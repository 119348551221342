import React from 'react';

type LabelStatus = {
    className: string;
    status: string;
};

const LabelStatus = ({ className, status }: LabelStatus) => {
    const STATUS_COMPLETED = 'completed';
    const STATUS_PENDING = 'pending';
    const STATUS_REJECTED = 'rejected';
    return (
        <div className={`${className}`}>
            <label>Estado:</label>
            <label className="ml-1">
                {status === STATUS_COMPLETED
                    ? 'Aprobado'
                    : status === STATUS_PENDING
                    ? 'Pendiente'
                    : status === STATUS_REJECTED
                    ? 'Rechazado'
                    : '--'}
            </label>
        </div>
    );
};

export default LabelStatus;
