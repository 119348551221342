import React, { ReactNode, useEffect, useState } from 'react';
import ButtonMain from '../components/buttons/button-main';
import Dialog from '@mui/material/Dialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import numeral from 'numeral';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LabelStatus from '../components/commons/label-status';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DownloadIcon from '../icons/download';
import { toPng } from 'html-to-image';
import { useAppSelector } from '../redux/selectors/app-selectors';
import { paymentsService } from '../api/services';
import Moment from 'react-moment';
import 'moment/locale/es';
import { paymentInterface } from '../interfaces/payments';
import RechargeIcon from '../icons/recharge';
import ConsumptionIcon from '../icons/consumption';
import ReferralIcon from '../icons/referral';
import PaymentInProgress from '../icons/payment-in-progress';
import { PAYMENT_METHODS } from '../utils/constants';
import moment from 'moment';
import Line from '../components/commons/line';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import AppartaLogoTypography from '../icons/apparta-logo-typography';
import Bonos from '../icons/bonos';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionsDetails = () => {
    const PAYMENT_PENDING = 'pending';
    const TYPE_PAYMENT = 'payment';
    const [open, setOpen] = React.useState(false);
    const selector = useAppSelector((state) => state);
    const [searchParams] = useSearchParams();
    const [payment, setPayment] = useState<paymentInterface | null>();
    const navigate = useNavigate();

    const paymentId = searchParams.get('paymentId');
    const [paymentsMethods, setPaymentsMethods] =
        useState<[string | null, string | null]>();

    const [payments, setPayments] = useState<[paymentInterface | null]>();

    useEffect(() => {
        getPayment({ paymentId });
        setOpen(!!paymentId);
    }, [paymentId]);

    const handleClickOpen = () => {};

    const handleClose = () => {
        navigate('/transactions-details');
        setPayment(null);
        setOpen(false);
    };

    const getPayment = ({ paymentId }: { paymentId: string | null }) => {
        if (paymentId)
            paymentsService.get(paymentId).then((payment: paymentInterface) => {
                setPayment(payment);
                setPaymentsMethods([
                    payment.amount_from_wallet ? 'AppartaPay' : null,
                    payment.payment_gateway ? payment.payment_gateway : null,
                ]);
            });
    };

    const getPayments = ({}) => {
        paymentsService
            .find({
                query: {
                    $limit: 10000,
                    $sort: {
                        createdAt: -1,
                    },
                },
            })
            .then((it: any) => {
                setPayments(it.data);
            });
    };

    const downloadBouncer = () => {
        const node = document.getElementById('bouncer');
        if (node) {
            toPng(node)
                .then(function (dataUrl) {
                    const img = new Image();
                    img.src = dataUrl;

                    document.body.appendChild(img);
                    img.click();
                    document.body.removeChild(img);

                    const a = document.createElement('a');
                    a.href = dataUrl;
                    a.download = `appartaPay-${payment?.id}.png`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })

                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }
    };

    const HaveSeeMore = (payment: paymentInterface) => {
        return (
            (payment && payment.type === 'reservation') ||
            payment.type === 'payment'
        );
    };

    type typesConfigByType = {
        pending: string;
        completed: string;
        rejected: string;
        icon: ReactNode;
        color: string;
        sign: string;
        description: string;
    };

    const configByType: {
        recharge: typesConfigByType;
        payment: typesConfigByType;
        reservation: typesConfigByType;
        coupon: typesConfigByType;
        referral: typesConfigByType;
        verification_credit_card: typesConfigByType;
    } = {
        recharge: {
            description: 'Recarga',
            icon: <RechargeIcon />,
            color: 'text-purple-main',
            sign: '+',
            pending: 'text-yellow-payment-pending',
            completed: 'text-green-main',
            rejected: 'text-red-payment-rejected',
        },
        verification_credit_card: {
            description: 'Verificación de tc',
            icon: <RechargeIcon />,
            color: 'text-purple-main',
            sign: '+',
            pending: 'text-yellow-payment-pending',
            completed: 'text-green-main',
            rejected: 'text-red-payment-rejected',
        },
        payment: {
            description: 'Consumo',
            icon: <ConsumptionIcon />,
            color: 'text-purple-main',
            sign: '-',
            pending: 'text-yellow-payment-pending',
            completed: 'text-red-payment-rejected',
            rejected: 'text-red-payment-rejected',
        },
        reservation: {
            description: 'Consumo',
            icon: <ConsumptionIcon />,
            color: 'text-purple-main',
            sign: '+',
            pending: 'text-yellow-payment-pending',
            completed: 'text-green-main',
            rejected: 'text-red-payment-rejected',
        },
        coupon: {
            description: 'Cupón',
            icon: <Bonos />,
            color: 'text-purple-main',
            sign: '+',
            pending: 'text-yellow-payment-pending',
            completed: 'text-green-main',
            rejected: 'text-red-payment-rejected',
        },
        referral: {
            description: 'Referral',
            icon: <ReferralIcon />,
            color: 'text-purple-main',
            sign: '+',
            pending: 'rexr-yellow-payment-pending',
            completed: 'text-green-main',
            rejected: 'text-red-payment-rejected',
        },
    };

    useEffect(() => {
        getPayments({});
    }, []);

    return (
        <div>
            <div className="flex">
                <div className="flex w-full justify-center">
                    <div className="flex flex-col w-full px-6">
                        {payments &&
                            payments.length &&
                            payments.map((payment) => (
                                <div className="flex flex-row items-center justify-center w-full mt-4">
                                    <div className="mr-6">
                                        {payment && payment.type
                                            ? configByType[payment?.type].icon
                                            : null}
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="flex justify-between w-full">
                                            <div className="font-extrabold text-md">
                                                {payment && payment.type
                                                    ? configByType[
                                                          payment?.type
                                                      ].description
                                                    : null}
                                            </div>
                                            <div
                                                className={`
                                             ${
                                                 payment && payment.type
                                                     ? configByType[
                                                           payment?.type
                                                       ][payment.status]
                                                     : null
                                             }
                                             font-extrabold`}
                                            >
                                                {payment && payment.type
                                                    ? configByType[
                                                          payment?.type
                                                      ].sign
                                                    : null}{' '}
                                                {payment
                                                    ? numeral(
                                                          payment.amount_net
                                                      ).format('$0,0')
                                                    : 0}
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <div className="min-w-[200px] items-start justify-end items-start text-left">
                                                <div className="flex flex-col justify-between w-full">
                                                    <div className="text-sm">
                                                        {payment &&
                                                        payment.type ===
                                                            TYPE_PAYMENT
                                                            ? `${payment.meta_to_establishment_name} - ${payment.meta_to_establishment_branch_address}`
                                                            : ''}
                                                    </div>
                                                    <div className="text-sm">
                                                        {payment &&
                                                            payment.payment_gateway &&
                                                            PAYMENT_METHODS[
                                                                payment
                                                                    .payment_gateway
                                                            ]}
                                                    </div>
                                                    <div className="text-sm">
                                                        {payment &&
                                                            payment.meta_cashback_percentage}
                                                    </div>
                                                </div>
                                                <div className="text-sm flex justify-between">
                                                    <div>
                                                        {payment &&
                                                            payment.createdAt &&
                                                            moment(
                                                                payment.createdAt
                                                            ).format(
                                                                'DD [de] MMMM [•] h:mm A z'
                                                            )}
                                                    </div>
                                                    {payment &&
                                                        payment.status !==
                                                            'pending' &&
                                                        HaveSeeMore(
                                                            payment
                                                        ) && (
                                                            <div className="flex">
                                                                <div
                                                                    className="text-sm text-purple-main underline underline-offset-1 font-bold ml-4"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `../transactions-details?paymentId=${payment.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    Ver mas
                                                                </div>
                                                                <div className="pl-0">
                                                                    <KeyboardArrowRightIcon />
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            {payment &&
                                                payment.status ===
                                                    PAYMENT_PENDING && (
                                                    <div className="flex justify-between w-full text-gray-400 text-sm min-w-[80px] items-center">
                                                        En progreso
                                                        <div>
                                                            <PaymentInProgress />
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="mt-2">
                                            <Line />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                style={{ top: 'unset' }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <div className="flex justify-end pr-4 ">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </div>
                </AppBar>
                <div id="bouncer" className="bg-white pt-2">
                    <header className="flex justify-center">
                        <AppartaLogoTypography />
                    </header>
                    <div className="flex flex-col items-center">
                        <Divider sx={{ mt: 2, px: 8, width: '84%' }} />
                        <label className="text-gray-400 mt-2 mb-4 text-sm">
                            <Moment format="DD-MMMM-YY hh:mm:ss a">
                                {payment?.createdAt}
                            </Moment>
                        </label>

                        <LabelStatus
                            status={
                                payment?.status ? payment.status : 'Procesando'
                            }
                            className="rounded-[10px] border-purple-main border-2 py-1 px-4 text-purple-main"
                        />
                    </div>
                    <div className="flex px-6 mt-8 flex-col">
                        <div>
                            <label>Monto pagado:</label>
                        </div>
                        <div>
                            <label className="text-2xl font-medium">
                                {numeral(payment?.amount_net).format('$0,0')}
                            </label>
                        </div>
                        <div className="mt-2">
                            <label>ID del pago:</label>
                        </div>
                        <div>
                            <label>{payment?.id}</label>
                        </div>
                        <div className="mt-2">
                            <label>Restaurante:</label>
                        </div>
                        <div>
                            <label>{payment?.meta_to_establishment_name}</label>
                        </div>
                        <div>
                            <label>
                                {payment?.meta_to_establishment_branch_address}
                            </label>
                        </div>
                        <div className="mt-2">
                            <label>Métodos de pago utilizados:</label>
                        </div>
                        {!!paymentsMethods &&
                            paymentsMethods.map((it) => (
                                <div className="mt-2 capitalize">
                                    {it}
                                    {it === 'Tarjeta de crédito' && (
                                        <div>121231232</div>
                                    )}
                                </div>
                            ))}
                        <div className="flex flex-row justify-between mt-10">
                            <div className="mb-[50px]">
                                <ButtonMain
                                    description="Compartir"
                                    onClick={handleClickOpen}
                                    Icon={
                                        <ShareOutlinedIcon
                                            sx={{ fontSize: 18 }}
                                        />
                                    }
                                    className="bg-purple-main"
                                />
                            </div>
                            <div
                                style={{
                                    backgroundColor: 'white',
                                }}
                            >
                                <ButtonMain
                                    description="Descargar"
                                    Icon={<DownloadIcon />}
                                    onClick={() => downloadBouncer()}
                                    className="border-purple-main border-[1px]"
                                    classNameDescription="text-purple-main"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default TransactionsDetails;
