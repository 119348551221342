type actionsCreditCardsTypes = {
    type: string;
    params: [{ [key: string]: any }];
};

export function actionsCreditCards({ type, params }: actionsCreditCardsTypes) {
    return {
        type: type,
        payload: [...params],
    };
}
