import React, { useEffect, useState } from 'react';
import NavBar from '../components/commons/nav-bar';
import SlideDialog from '../components/modals/slide-dialog';
import LoginForm from '../components/forms/login';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Main(props: any) {
    const [searchParams] = useSearchParams();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const authenticate = searchParams.get('Authentication');

    const dispatch = useDispatch();

    useEffect(() => {
        const authenticate = searchParams.get('Authentication');

        setOpenModal(authenticate === 'false');
    }, [searchParams]);

    const handleClose = () => {
        navigate(-1);
        // setOpenModal(false);
    };

    return (
        <div>
            <NavBar></NavBar>
            <SlideDialog
                children={<LoginForm handleClose={handleClose} />}
                open={openModal}
                setOpenModal={setOpenModal}
            />
            <div>{props.children}</div>
        </div>
    );
}

export default Main;
