import React from 'react';
import QuestionIcon from '../../icons/question';

const Footer = (props: any) => {
    return (
        <div className="tooltip">
            <QuestionIcon />
            <span className="tooltiptext tooltip-bottom">
                Pagando con AppartaPay tendrás diferentes beneficios y podrás
                elegir un método de pago adicional
            </span>
        </div>
    );
};

export default Footer;
