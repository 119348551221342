import React, { KeyboardEventHandler, useEffect } from 'react';
import numeral from 'numeral';
import TextField from '@mui/material/TextField';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

enum type {
    text = 'text',
    number = 'number',
}

type textFieldMain = {
    name: string;
    type?: string;
    onChange(
        e: React.ChangeEvent<HTMLInputElement>
    ): void | React.ChangeEvent<HTMLInputElement>;
    placeHolder?: string;
    className?: string;
    focus?: boolean;
    value?: string;
    autoComplete?: 'on' | 'off';
    onKeyDown?: React.KeyboardEventHandler;
    inputProps?: object;
    props?: any;
    id?: string;
};

function TextFieldMain({
    id,
    props,
    type,
    onChange,
    placeHolder,
    name,
    className,
    focus,
    value,
    autoComplete,
    onKeyDown,
    inputProps,
}: textFieldMain) {
    useEffect(() => {}, []);

    return (
        <div className="w-full">
            {/*<TextField*/}
            {/*    id={id}*/}
            {/*    {...props}*/}
            {/*    type={type}*/}
            {/*    name={name}*/}
            {/*    placeholder={placeHolder}*/}
            {/*    autoFocus={focus}*/}
            {/*    value={value}*/}
            {/*    autoComplete={autoComplete}*/}
            {/*    onKeyDown={onKeyDown}*/}
            {/*    className={`border-2 border-purple-main rounded-[42px] w-full h-[50px] pl-2.5  focus:outline-none placeholder-shown:text-sm ${className}`}*/}
            {/*    variant="outlined"*/}
            {/*    InputLabelProps={{*/}
            {/*        shrink: true,*/}
            {/*    }}*/}
            {/*    // onChange={(e) => onChange(e)}*/}
            {/*/>*/}
            <input
                onChange={(e) => onChange(e)}
                className={`border-[1px] border-purple-main rounded-[42px] w-full h-[50px] pl-2.5  focus:outline-none placeholder-shown:text-sm ${className}`}
                type={type}
                name={name}
                min="1500"
                placeholder={placeHolder}
                autoFocus={focus}
                value={value}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
            ></input>
        </div>
    );
}

export default TextFieldMain;
