import React from 'react';
import ButtonMain from '../buttons/button-main';
import VerifiedIcon from '../../icons/verified';
import numeral from 'numeral';
import CircularProgress from '@mui/material/CircularProgress';

type FooterTypes = {
    isLoading: boolean;
    totalAmount: number | undefined;
    sendPayment(): void;
    enableButtonPaid?: boolean;
};

const Footer = ({
    isLoading,
    totalAmount,
    sendPayment,
    enableButtonPaid,
}: FooterTypes) => {
    return (
        <footer className="fixed bottom-0 h-24 w-full max-w-[420px] shadow-card-shadow bg-white">
            <div className="flex flex-row justify-around items-center h-full">
                <div className="flex flex-col items-start">
                    <div>Total a pagar</div>
                    <div className="text-lg font-bold">
                        {totalAmount ? numeral(totalAmount).format('$0,0') : 0}
                    </div>
                    <div className="text-s text-green-main mb-1">
                        Te ahorraste $0
                    </div>
                </div>
                <div>
                    <ButtonMain
                        disabled={!enableButtonPaid}
                        description="Pagar"
                        Icon={
                            isLoading ? (
                                <CircularProgress
                                    size={16}
                                    style={{
                                        color: 'white',
                                        marginBottom: -1,
                                    }}
                                />
                            ) : (
                                <VerifiedIcon />
                            )
                        }
                        onClick={() => (isLoading ? () => {} : sendPayment())}
                        className={`${
                            enableButtonPaid
                                ? 'bg-purple-main'
                                : 'bg-gray-disabled'
                        }`}
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
