import React, { ReactNode, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonMain from '../buttons/button-main';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { creditCardVerifyService } from '../../api/services';
import { creditCardInterface } from '../../interfaces/credit-cards';
import { actionAlertNotification } from '../../redux/actions/alert-notifications';
import { useNavigate } from 'react-router-dom';

type verifyCreditCardTypes = {
    setOpenVerifyStepTwo(b: boolean): void;
    creditCard: creditCardInterface;
    open: boolean;
    handleClose(): void;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyCreditCard = ({
    creditCard,
    open,
    handleClose,
    setOpenVerifyStepTwo,
}: verifyCreditCardTypes) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();

    const paidVerification = () => {
        setIsLoading(true);
        const payLoad = {
            paymentVerify: true,
            credit_card_id: creditCard.id,
        };
        creditCardVerifyService
            .create(payLoad)
            .then((it: any) => {
                handleClose();
                setOpenVerifyStepTwo(true);
                setIsLoading(false);
            })
            .catch((e: any) => {
                dispatch(
                    actionAlertNotification({
                        TYPE: 'SET_ALERT_NOTIFICATIONS',
                        params: {
                            open: true,
                            description: e.message,
                            severity: 'error',
                        },
                    })
                );
                console.log(e);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [open]);

    return (
        <div>
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{ top: 'unset' }}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <div className="flex justify-end pr-4 ">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    handleClose();
                                    navigate('/');
                                }}
                                aria-label="close"
                            >
                                <CloseIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </div>
                    </AppBar>
                    <div className="flex justify-center flex-col text-center">
                        <div className="mb-8">
                            <img
                                className="inline-block rounded-md ring-2 ring-white w-[200px]"
                                src={
                                    'https://s3.amazonaws.com/appartaapp/appartaPay/images/SEGURIDAD%20CANDADO.png'
                                }
                                alt=""
                            />
                        </div>
                        <div>
                            <div className="mb-4">
                                <label className="font-semibold">
                                    Protege tu cuenta
                                </label>
                            </div>

                            <div className="mx-5">
                                <p className="text-left leading-5">
                                    Para proteger tu cuenta, cobraremos un monto
                                    aleatorio, el cual debes ingresar a
                                    continuación para verificar la validez de
                                    esta tarjeta.{' '}
                                    <span className="font-semibold">
                                        El monto que se cobra a tu tarjeta será
                                        reembolsado
                                    </span>{' '}
                                    en tu cuenta de appartaPay de manera
                                    automática , luego de ser verificada.
                                </p>
                            </div>
                            <div className="flex justify-center mt-6 mb-4">
                                <ButtonMain
                                    description="Verificar"
                                    onClick={() => paidVerification()}
                                    className="bg-purple-main px-14"
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="text-xs text-purple-main underline underline-offset-1 mb-10">
                                ¿Por qué debo verificar mi tarjeta?
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default VerifyCreditCard;
