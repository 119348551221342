import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectángulo_6559"
                        data-name="Rectángulo 6559"
                        width="20"
                        height="20"
                        fill="#6b24f8"
                    />
                </clipPath>
            </defs>
            <g
                id="Grupo_17375"
                data-name="Grupo 17375"
                clip-path="url(#clip-path)"
            >
                <path
                    id="Trazado_1342"
                    data-name="Trazado 1342"
                    d="M8,17l3.333,3.333L14.667,17"
                    transform="translate(-1.333 -2.833)"
                    fill="none"
                    stroke="#6b24f8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    id="Trazado_1343"
                    data-name="Trazado 1343"
                    d="M12,12v7.5"
                    transform="translate(-2 -2)"
                    fill="none"
                    stroke="#6b24f8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    id="Trazado_1344"
                    data-name="Trazado 1344"
                    d="M17.565,15.573A4.167,4.167,0,0,0,15.165,8h-1.05a6.667,6.667,0,1,0-11.45,6.075"
                    transform="translate(-0.165 -0.498)"
                    fill="none"
                    stroke="#6b24f8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
        </svg>
    );
};

export default Verified;
