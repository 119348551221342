import React from 'react';

const LogoAppartaPay = (props: any) => {
    return (
        <svg
            id="Componente_210_249"
            data-name="Componente 210 – 249"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path
                id="Icon_awesome-user-circle"
                data-name="Icon awesome-user-circle"
                d="M11.33,4.708A4.338,4.338,0,1,1,6.992,9.045,4.343,4.343,0,0,1,11.33,4.708Zm0,7.764A3.426,3.426,0,1,0,7.9,9.045,3.43,3.43,0,0,0,11.33,12.472ZM9.212,14.236a1.532,1.532,0,0,1,.446.068l.007,0a5.046,5.046,0,0,0,3.327,0l.008,0a1.532,1.532,0,0,1,.446-.068,5.378,5.378,0,0,1,4.748,2.88.456.456,0,0,1-.055.508,8.9,8.9,0,0,1-13.621,0,.456.456,0,0,1-.055-.508A5.378,5.378,0,0,1,9.212,14.236Zm.177.939a.62.62,0,0,0-.177-.028,4.46,4.46,0,0,0-3.787,2.126,7.987,7.987,0,0,0,11.81,0,4.46,4.46,0,0,0-3.787-2.126.62.62,0,0,0-.177.028,5.952,5.952,0,0,1-3.881,0Z"
                transform="translate(0.67 0.131)"
                fill="#fff"
            />
            <path
                id="Icon_awesome-user-circle_-_Contorno"
                data-name="Icon awesome-user-circle - Contorno"
                d="M11.328,4.558A4.486,4.486,0,1,1,6.842,9.044,4.491,4.491,0,0,1,11.328,4.558Zm0,8.676a4.19,4.19,0,1,0-4.19-4.19A4.194,4.194,0,0,0,11.328,13.233Zm0-7.764A3.574,3.574,0,1,1,7.754,9.044,3.578,3.578,0,0,1,11.328,5.469Zm0,6.852A3.278,3.278,0,1,0,8.05,9.044,3.282,3.282,0,0,0,11.328,12.322ZM9.21,14.086a1.68,1.68,0,0,1,.489.075l.01,0a4.9,4.9,0,0,0,3.236,0l.011,0a1.68,1.68,0,0,1,.489-.075,5.526,5.526,0,0,1,4.879,2.959.6.6,0,0,1-.072.673,9.049,9.049,0,0,1-13.847,0,.6.6,0,0,1-.072-.673A5.526,5.526,0,0,1,9.21,14.086Zm2.117.649a5.51,5.51,0,0,1-1.71-.289l0,0a1.385,1.385,0,0,0-.4-.062,5.23,5.23,0,0,0-4.617,2.8.306.306,0,0,0,.037.343,8.753,8.753,0,0,0,13.4,0,.306.306,0,0,0,.037-.343,5.23,5.23,0,0,0-4.617-2.8,1.384,1.384,0,0,0-.4.062l-.006,0A5.462,5.462,0,0,1,11.328,14.735ZM9.21,15a.768.768,0,0,1,.22.034,5.805,5.805,0,0,0,3.793,0A.773.773,0,0,1,13.445,15a4.588,4.588,0,0,1,3.913,2.2l.059.1-.076.083a8.135,8.135,0,0,1-12.028,0l-.076-.083.059-.1A4.588,4.588,0,0,1,9.21,15Zm2.117.649a6.4,6.4,0,0,1-1.986-.332.468.468,0,0,0-.132-.021,4.289,4.289,0,0,0-3.6,1.961,7.838,7.838,0,0,0,11.437,0,4.289,4.289,0,0,0-3.6-1.961.473.473,0,0,0-.134.021A6.436,6.436,0,0,1,11.328,15.647Z"
                transform="translate(0.672 0.133)"
                fill="#fff"
            />
            <path
                id="Icon_awesome-user-circle-2"
                data-name="Icon awesome-user-circle"
                d="M10.852.474a10.94,10.94,0,1,0,10.94,10.94A10.938,10.938,0,0,0,10.852.474m0-.912A11.853,11.853,0,0,1,19.233,19.8a11.855,11.855,0,0,1-16.762,0A11.852,11.852,0,0,1,10.852-.437Z"
                transform="translate(1.148 0.586)"
                fill="#fff"
            />
            <path
                id="Icon_awesome-user-circle_-_Contorno-2"
                data-name="Icon awesome-user-circle - Contorno"
                d="M10.85-.587A12,12,0,0,1,19.336,19.9a12,12,0,0,1-16.972,0A12,12,0,0,1,10.85-.587Zm0,23.7a11.7,11.7,0,0,0,8.276-19.98,11.706,11.706,0,0,0-16.553,0,11.7,11.7,0,0,0,8.276,19.98Zm0-22.792a11.089,11.089,0,0,1,7.841,18.929A11.089,11.089,0,0,1,3.009,3.571,11.016,11.016,0,0,1,10.85.324Zm0,21.88A10.793,10.793,0,0,0,18.482,3.781,10.793,10.793,0,0,0,3.218,19.044,10.722,10.722,0,0,0,10.85,22.2Z"
                transform="translate(1.15 0.587)"
                fill="#fff"
            />
        </svg>
    );
};

export default LogoAppartaPay;
