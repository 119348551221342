const INITIAL_STATE = {
    establishment_branch: {},
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_ESTABLISHMENT_BRANCH':
            return {
                ...state,
                establishment_branch: { ...action.payload },
            };
        default:
            return { ...state };
    }
}
