import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.834"
            height="13.343"
            viewBox="0 0 10.834 13.343"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectángulo_6557"
                        data-name="Rectángulo 6557"
                        width="7.271"
                        height="7.271"
                        transform="translate(0 0)"
                        fill="#fff"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect
                        id="Rectángulo_6556"
                        data-name="Rectángulo 6556"
                        width="7.271"
                        height="7.271"
                        fill="#fff"
                    />
                </clipPath>
            </defs>
            <g
                id="Grupo_17367"
                data-name="Grupo 17367"
                transform="translate(-0.6 -0.6)"
            >
                <path
                    id="Trazado_1315"
                    data-name="Trazado 1315"
                    d="M6.017,13.543s5.017-2.509,5.017-6.271V2.881L6.017,1,1,2.881v4.39C1,11.034,6.017,13.543,6.017,13.543Z"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="0.8"
                />
                <g
                    id="Grupo_17366"
                    data-name="Grupo 17366"
                    transform="translate(2.382 3.636)"
                    clip-path="url(#clip-path)"
                >
                    <g
                        id="Grupo_17365"
                        data-name="Grupo 17365"
                        transform="translate(0 0)"
                        clip-path="url(#clip-path-2)"
                    >
                        <path
                            id="Trazado_1316"
                            data-name="Trazado 1316"
                            d="M7.54,12.057A2.613,2.613,0,1,0,4.927,9.444,2.613,2.613,0,0,0,7.54,12.057Z"
                            transform="translate(-3.846 -5.81)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="0.8"
                        />
                    </g>
                    <path
                        id="Trazado_1317"
                        data-name="Trazado 1317"
                        d="M9.623,9.747l-1.8,1.8-.817-.817"
                        transform="translate(-4.623 -6.898)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="0.8"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Verified;
