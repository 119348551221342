import React from 'react';

const Question = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <g
                id="Grupo_16049"
                data-name="Grupo 16049"
                transform="translate(-287 -352)"
            >
                <circle
                    id="Elipse_124"
                    data-name="Elipse 124"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(287 352)"
                    fill="#35d172"
                />
                <path
                    id="Trazado_902"
                    data-name="Trazado 902"
                    d="M2.681-3.9H3.852v-.435a1.766,1.766,0,0,1,.1-.616,1.314,1.314,0,0,1,.335-.489,3.591,3.591,0,0,1,.659-.475,3.539,3.539,0,0,0,.743-.6,2.412,2.412,0,0,0,.485-.76,2.521,2.521,0,0,0,.171-.947v-.013a2.2,2.2,0,0,0-.358-1.235,2.486,2.486,0,0,0-1-.863A3.288,3.288,0,0,0,3.5-10.649a3.241,3.241,0,0,0-1.573.348,2.425,2.425,0,0,0-.97.937A2.937,2.937,0,0,0,.586-8.059v.013l1.138-.007.013-.007a1.761,1.761,0,0,1,.251-.823,1.447,1.447,0,0,1,.592-.535,1.937,1.937,0,0,1,.877-.187,1.9,1.9,0,0,1,.87.187,1.4,1.4,0,0,1,.569.509,1.36,1.36,0,0,1,.2.736v.013a1.721,1.721,0,0,1-.107.629,1.429,1.429,0,0,1-.345.505,3.7,3.7,0,0,1-.626.472,3.945,3.945,0,0,0-.756.576,1.926,1.926,0,0,0-.442.659,2.173,2.173,0,0,0-.141.8ZM3.357-.8a.9.9,0,0,0,.666-.261.912.912,0,0,0,.258-.669A.921.921,0,0,0,4.022-2.4a.9.9,0,0,0-.666-.261.906.906,0,0,0-.669.261.915.915,0,0,0-.261.676.906.906,0,0,0,.261.669A.906.906,0,0,0,3.357-.8Z"
                    transform="translate(291.414 365.649)"
                    fill="#fff"
                    stroke="#fff"
                    stroke-width="0.5"
                />
            </g>
        </svg>
    );
};

export default Question;
