import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
        >
            <g
                id="Grupo_17385"
                data-name="Grupo 17385"
                transform="translate(-16 -122)"
            >
                <g
                    id="Grupo_16217"
                    data-name="Grupo 16217"
                    transform="translate(16 122)"
                >
                    <circle
                        id="Elipse_145"
                        data-name="Elipse 145"
                        cx="21"
                        cy="21"
                        r="21"
                        fill="#f2f2f2"
                    />
                </g>
                <g
                    id="noun-store-4609977"
                    transform="translate(-96.053 80.011)"
                >
                    <path
                        id="Trazado_545"
                        data-name="Trazado 545"
                        d="M141.037,60.777a.333.333,0,0,0-.019-.045c0-.007,0-.013-.007-.021L137.939,56.1c0-.007-.011-.011-.017-.017a.326.326,0,0,0-.036-.036.445.445,0,0,0-.038-.026.163.163,0,0,0-.041-.017.161.161,0,0,0-.051-.009c-.007,0-.013,0-.021,0h-8.373c-.007,0-.013,0-.021,0a.235.235,0,0,0-.052.011.182.182,0,0,0-.039.017.458.458,0,0,0-.039.026.293.293,0,0,0-.034.034.147.147,0,0,0-.017.017l-3.069,4.614a.064.064,0,0,0-.007.021.332.332,0,0,0-.019.045.23.23,0,0,0-.009.049c0,.007,0,.013,0,.021V62.06a1.45,1.45,0,0,0,1.209,1.425v7.259a.243.243,0,0,0,.244.244H139.6a.243.243,0,0,0,.244-.244V63.484a1.447,1.447,0,0,0,1.209-1.425V60.847c0-.007,0-.013,0-.021a.447.447,0,0,1-.013-.049Zm-11.541-4.3h8.11l2.747,4.127h-13.6Zm8.648,4.614v.971a.962.962,0,0,1-.962.962h-.009a.962.962,0,0,1-.962-.962v-.971Zm-2.418,0v.971a.962.962,0,0,1-.962.962h-.009a.962.962,0,0,1-.962-.962v-.971Zm-2.419,0v.971a.962.962,0,0,1-.962.962h-.009a.962.962,0,0,1-.962-.962v-.971Zm-2.418,0v.971a.962.962,0,0,1-.962.962h-.009a.962.962,0,0,1-.962-.962v-.971ZM127.5,63.022l-.007,0a.961.961,0,0,1-.958-.96v-.971h1.931v.971a.961.961,0,0,1-.96.96.007.007,0,0,0-.006,0Zm5.36,7.479h-3.425V65.268h3.425Zm6.49,0h-6V65.024a.243.243,0,0,0-.244-.244H129.2a.243.243,0,0,0-.244.244V70.5h-1.205V63.485a1.447,1.447,0,0,0,.966-.622,1.448,1.448,0,0,0,1.205.647h.009a1.448,1.448,0,0,0,1.205-.647,1.448,1.448,0,0,0,1.205.647h.009a1.448,1.448,0,0,0,1.205-.647,1.448,1.448,0,0,0,1.205.647h.009a1.448,1.448,0,0,0,1.205-.647,1.448,1.448,0,0,0,1.205.647h.009a1.448,1.448,0,0,0,1.205-.647,1.448,1.448,0,0,0,.966.622V70.5Zm1.207-8.438a.96.96,0,0,1-.958.96l-.007,0-.007,0a.963.963,0,0,1-.96-.96v-.971h1.931v.971Z"
                        fill="none"
                        stroke="#6b24f8"
                        stroke-linejoin="round"
                        stroke-width="0.5"
                    />
                    <path
                        id="Trazado_546"
                        data-name="Trazado 546"
                        d="M377.355,318.59h-3.315a.243.243,0,0,0-.244.244v2.673a.243.243,0,0,0,.244.244h3.315a.243.243,0,0,0,.244-.244v-2.674A.244.244,0,0,0,377.355,318.59Zm-.244,2.673h-2.827v-2.186h2.829v2.186Z"
                        transform="translate(-239.45 -253.809)"
                        fill="none"
                        stroke="#6b24f8"
                        stroke-linejoin="round"
                        stroke-width="0.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Verified;
