import { paymentMethodInterface } from '../interfaces/payments-methods';

export const TIMEOUT = 60000;

// export const HOST_API = 'api.apparta.co';
export const HOST_API = 'api-staging.apparta.co';
// export const HOST_API = 'localhost:3030';
export const PROTOCOL_HTTP = 'https';
// export const PROTOCOL_HTTP = 'http';
export const ACCESS_TOKEN = 'feathers-jwt';
export const STORAGE = window.localStorage;
export const S3_ROOT_PATH = 'https://s3.amazonaws.com/appartaapp';
export const s3PathImageHandrer = 'https://d1gup81n3r8p82.cloudfront.net';
export const HOST_FRONT = 'https://poketmenu.app';
export const LOGOAPP = '/static/icon-poketmenu.png';
export const LINK_WHATSAPP = 'https://api.whatsapp.com/send?phone=573237833145';

export const PAYMENT_METHOD_ASSETS: paymentMethodInterface = {
    nequi: 'payment-methods-images/logo-nequi.png',
    appartaPay: 'payment-methods-images/logo-apparta-pay.png',
    bancolombiaTransfer: 'payment-methods-images/logo-bancolombia.png',
    addCreditCard: 'payment-methods-images/add-credit-card.png',
    pse: 'payment-methods-images/logo-pse.png',
    bonus: 'payment-methods-images/bonus.png',
};

export const PAYMENT_METHODS = {
    credit_card: 'Tarjeta de credito',
    bancolombia_transfer: 'Bancolombia',
    pse: 'PSE',
    wallet: 'wallet',
    daviplata: 'Daviplata',
    nequi: 'Nequi',
};

export const ROUTES_ALIAS: {
    '/transactions-details': string;
    '/add-credit-card': string;
} = {
    '/transactions-details': 'Historial de movimientos',
    '/add-credit-card': 'Crear tarjeta de credito',
};
