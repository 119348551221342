const INITIAL_STATE = {
    pay_account: {},
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_PAY_ACCOUNT':
            return {
                ...state,
                pay_account: { ...action.payload },
            };
        default:
            return { ...state };
    }
}
