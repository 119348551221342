import React from 'react';

const Closeicon = (props: any) => {
    return (
        <svg
            id="Grupo_17103"
            data-name="Grupo 17103"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 24 24"
        >
            <circle
                id="Elipse_181"
                data-name="Elipse 181"
                cx="12"
                cy="12"
                r="12"
                fill="#f0efef"
            />
            <path
                id="Trazado_1179"
                data-name="Trazado 1179"
                d="M16.435,7.565l-8.87,8.87"
                fill="none"
                stroke="#6b24fa"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                id="Trazado_1180"
                data-name="Trazado 1180"
                d="M7.565,7.565l8.87,8.87"
                fill="none"
                stroke="#6b24fa"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </svg>
    );
};

export default Closeicon;
