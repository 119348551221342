import React, { ReactNode, useEffect, useState } from 'react';
import CreditCardFrom from '../components/forms/credit-card';
import InputMain from '../components/commons/text-field-main';

const CreditCardCreate = () => {
    return (
        <div id="container" className="px-6 pb-20">
            <CreditCardFrom />
        </div>
    );
};

export default CreditCardCreate;
