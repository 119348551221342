import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { creditCardInterface } from '../../interfaces/credit-cards';
import ButtonMain from '../buttons/button-main';
import InputMain from '../../components/commons/text-field-main';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { creditCardVerifyService } from '../../api/services';
import { actionAlertNotification } from '../../redux/actions/alert-notifications';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/selectors/app-selectors';

function onlyNumbers(e: any) {
    const keyCode = !e.charCode ? e.which : e.charCode;
    const regex = new RegExp('^[0-9 ]+$');
    const key = String.fromCharCode(keyCode);
    if (keyCode !== 8 && !regex.test(key)) {
        e.preventDefault();
        return false;
    }
}

type verifyCreditCardTypes = {
    creditCard: creditCardInterface;
    open: boolean;
    handleClose(): void;
    setVerificationFailed(b: boolean): void;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyCreditCard = ({
    creditCard,
    open,
    handleClose,
    setVerificationFailed,
}: verifyCreditCardTypes) => {
    const [amount, setAmount] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selector = useAppSelector((state) => state);

    const establishmentBranch =
        selector.rootReducer.establishment_branch.establishment_branch;

    const handleChange = (e: any) => setAmount(e.target.value);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [open]);

    const verifyCreditCard = () => {
        setIsLoading(true);

        const payLoad = {
            amount_verify: amount ? +amount : null,
            credit_card_id: creditCard.id,
            checkCreditCard: true,
        };
        creditCardVerifyService
            .create(payLoad)
            .then((creditCard: any) => {
                if (creditCard.status === 'pending_verification') {
                    const maxIntent = 3;
                    const intent = maxIntent - creditCard.attempts_to_verify;

                    dispatch(
                        actionAlertNotification({
                            TYPE: 'SET_ALERT_NOTIFICATIONS',
                            params: {
                                open: true,
                                description: `Valor ingresado incorrecto (${intent} intento${
                                    intent > 1 ? 's' : ''
                                } restante${intent > 1 ? 's' : ''})`,
                                severity: 'error',
                            },
                        })
                    );
                } else if (creditCard.status === 'verified') {
                    dispatch(
                        actionAlertNotification({
                            TYPE: 'SET_ALERT_NOTIFICATIONS',
                            params: {
                                open: true,
                                description: 'Tarjeta de credito verificada',
                                severity: 'success',
                            },
                        })
                    );

                    handleClose();
                    navigate(`../apparta?branchId=${establishmentBranch.id}`);
                }
                setIsLoading(false);
            })
            .catch((e: any) => {
                setVerificationFailed(true);

                setIsLoading(false);
            });
    };

    return (
        <div>
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{ top: 'unset' }}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <div className="flex justify-end pr-4 ">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => handleClose()}
                                aria-label="close"
                            >
                                <CloseIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </div>
                    </AppBar>
                    <div className="flex justify-center flex-col text-center">
                        <div className="mb-8">
                            <img
                                className="inline-block rounded-md ring-2 ring-white w-[200px]"
                                src={
                                    'https://s3.amazonaws.com/appartaapp/appartaPay/images/CLAVE%20DE%20SEGURIDAD.png'
                                }
                                alt=""
                            />
                        </div>
                        <div>
                            <div className="mb-4">
                                <label className="font-semibold">
                                    Ingresa el monto que te cobramos
                                </label>
                            </div>

                            <div className="mx-5">
                                <p className="text-left leading-5">
                                    Realizamos un cargo mínimo en tu tarjeta con
                                    un valor aleatorio a nombre de Apparta
                                    Verificación.
                                </p>
                            </div>
                            <div className="mx-5 mt-8 mt-2">
                                <label className="text-gray-400 text-sm">
                                    Fecha 19/08/2022 Hora 03:27 PM
                                </label>
                            </div>

                            <div className="flex w-full justify-center items-center">
                                <div className="font-semibold text-lg flex">
                                    <div className="invisible">$</div>$
                                    <div className="invisible">$</div>
                                </div>
                                <div>
                                    <InputMain
                                        name="number"
                                        onChange={(e) => handleChange(e)}
                                        value={amount}
                                        placeHolder="542"
                                        className={
                                            'border-0 pl-3 w-1/3 w-[60px] rounded-0 placeholder-shown:text-xl'
                                        }
                                        focus={true}
                                        autoComplete={'off'}
                                        onKeyDown={onlyNumbers}
                                    />
                                    <div className="bg-purple-main h-[3px] w-full "></div>
                                </div>
                                <div className="font-semibold text-lg ">
                                    COP
                                </div>
                            </div>
                            <div className="flex justify-center mt-6 mb-10">
                                <ButtonMain
                                    disabled={!(amount && amount?.length > 2)}
                                    description="Verificar"
                                    onClick={() => verifyCreditCard()}
                                    className="bg-purple-main px-14"
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default VerifyCreditCard;
