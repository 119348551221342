import React, { useEffect } from 'react';
import Checkout from '../views/checkout';
import TransactionsDetails from '../views/transactions-details';
import CreditCardCreate from '../views/credit-card-create';

const Component = (props: any) => {
    return <>component</>;
};

const checkoutRoutes = [
    {
        path: '/:id',
        component: <Checkout />,
        exact: true,
    },
    {
        path: '/transactions-details',
        component: <TransactionsDetails />,
        exact: true,
    },
];

const creditCardRoutes = [
    {
        path: '/add-credit-card',
        component: <CreditCardCreate />,
        exact: true,
        name: 'add-credit-card',
    },
];

export { checkoutRoutes, creditCardRoutes };
