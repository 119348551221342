import axios from 'axios';
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import { HOST_API, PROTOCOL_HTTP } from '../utils/constants';
import { TIMEOUT, ACCESS_TOKEN, STORAGE } from '../utils/constants';

// Client
// @ts-ignore
const app = feathers();
// Rest Config
const restClient = rest(`${PROTOCOL_HTTP}://${HOST_API}`);

app.configure(
    auth({
        path: '/authentication',
        storageKey: ACCESS_TOKEN,
        storage: STORAGE,
    })
);

export const retryLogin = () =>
    app.authenticate({
        strategy: 'jwt',
        accessToken: STORAGE.getItem(ACCESS_TOKEN),
    });

app.configure(restClient.axios(axios));

export const ClientFeathers = app;
