import React, { useEffect, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

type slideDialogtypes = {
    open: boolean;
    children: ReactNode;
    setOpenModal(arg0: boolean): void;
};

function SlideDialog({ open, children, setOpenModal }: slideDialogtypes) {
    useEffect(() => {}, []);

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <div>
            {open && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    style={{ padding: '0px !important' }}
                >
                    {children}
                </Dialog>
            )}
        </div>
    );
}

export default SlideDialog;
