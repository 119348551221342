import React, { useState } from 'react';
import ButtonMain from '../buttons/button-main';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import InputMain from '../commons/text-field-main';
import { actionPaymentMethodSelected } from '../../redux/actions/payment-method-selected';
import { useAppSelector } from '../../redux/selectors/app-selectors';

type PaymentNequi = {
    handleClose(): void;
    defaultNumber?: string;
    setPaymentWith?(): void;
};

const PaymentWithNequi = ({
    handleClose,
    defaultNumber,
    setPaymentWith,
}: PaymentNequi) => {
    type otpTypes = {
        strategy: string;
        phone: string;
        otp: number;
        countryCode: string;
    };
    const selector = useAppSelector((state) => state);

    const user = selector.rootReducer.auth.user;
    const [nequiNumber, setNequiNumber] = useState<string>(user.phone);

    const dispatch = useDispatch();

    const setAdditionalDataPayment = () => {
        if (!nequiNumber) {
            dispatch(
                actionPaymentMethodSelected({
                    TYPE: 'SET_PAYMENT_METHOD_SELECTED',
                    params: {
                        payment_method_selected: null,
                        credit_card_id: null,
                    },
                })
            );
            if (setPaymentWith) {
                setPaymentWith();
            }
        } else {
            dispatch(
                actionPaymentMethodSelected({
                    TYPE: 'SET_DATA_ADDITIONAL_PAYMENT',
                    params: {
                        phone_number: nequiNumber,
                        credit_card_id: null,
                    },
                })
            );
        }

        handleClose();
    };

    const closed = () => {
        dispatch(
            actionPaymentMethodSelected({
                TYPE: 'SET_PAYMENT_METHOD_SELECTED',
                params: {
                    payment_method_selected: null,
                    credit_card_id: null,
                },
            })
        );
        handleClose();
    };

    return (
        <div className="shadow-card-shadow bg-white">
            <div className="flex flex-col px-2 py-2 w-60">
                <div className="w-full flex justify-between w-full min-w-full">
                    <div className="font-sm font-medium text-gray-400">
                        Verifica tu celular
                    </div>
                    <div
                        className="align-end"
                        onClick={() => {
                            closed();
                        }}
                    >
                        X
                    </div>
                </div>
                <div>
                    <InputMain
                        type="text"
                        name="nequiNumber"
                        onChange={(e) => setNequiNumber(e.target.value)}
                        placeHolder="celular"
                        className={'border-0'}
                        focus={true}
                        value={nequiNumber}
                    />
                </div>
                <div className="mt-3 flex justify-center">
                    <ButtonMain
                        description="Verificar"
                        onClick={() => {
                            setAdditionalDataPayment();
                        }}
                        className={'h-6 bg-purple-main'}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentWithNequi;
