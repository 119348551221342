import React from 'react';

const Referral = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
        >
            <g
                id="Grupo_17398"
                data-name="Grupo 17398"
                transform="translate(-19.5 -532)"
            >
                <g
                    id="Grupo_17392"
                    data-name="Grupo 17392"
                    transform="translate(3.5 410)"
                >
                    <g
                        id="Grupo_16217"
                        data-name="Grupo 16217"
                        transform="translate(16 122)"
                    >
                        <g id="Grupo_17397" data-name="Grupo 17397">
                            <circle
                                id="Elipse_145"
                                data-name="Elipse 145"
                                cx="21"
                                cy="21"
                                r="21"
                                fill="#f2f2f2"
                            />
                        </g>
                    </g>
                </g>
                <path
                    id="Unión_14"
                    data-name="Unión 14"
                    d="M-972.5,15h-5.569a.539.539,0,0,1-.539-.539V8.039h-.853A.539.539,0,0,1-980,7.5V4.019a.539.539,0,0,1,.539-.539h1.893a2.266,2.266,0,0,1-.343-1.2A2.282,2.282,0,0,1-975.633,0a3.375,3.375,0,0,1,2.952,2.019c.065.119.125.237.181.354.055-.117.115-.235.18-.354A3.375,3.375,0,0,1-969.368,0a2.282,2.282,0,0,1,2.279,2.279,2.266,2.266,0,0,1-.343,1.2h1.892a.539.539,0,0,1,.539.539V7.5a.539.539,0,0,1-.539.539h-.853v6.422a.539.539,0,0,1-.539.539Zm5.03-1.078V8.039h-4.491v5.884Zm-10.06,0h4.491V8.039h-4.491Zm11.452-6.961v-2.4h-5.884v2.4h5.884Zm-6.961,0v-2.4h-5.884v2.4h5.884Zm3.671-3.481a1.2,1.2,0,0,0,1.2-1.2,1.2,1.2,0,0,0-1.2-1.2,2.341,2.341,0,0,0-2.008,1.461,6.1,6.1,0,0,0-.413.942Zm-3.845,0a6.045,6.045,0,0,0-.423-.962,2.329,2.329,0,0,0-2-1.441,1.2,1.2,0,0,0-1.2,1.2,1.2,1.2,0,0,0,1.2,1.2Z"
                    transform="translate(1013 545.5)"
                    fill="#6b24f8"
                />
            </g>
        </svg>
    );
};

export default Referral;
