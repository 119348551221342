const INITIAL_STATE = {
    wallet_user: {
        balance: 0,
        id: null,
    },
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_WALLET_USER':
            return {
                ...state,
                wallet_user: { ...action.payload },
            };
        default:
            return { ...state };
    }
}
