import React, { useState, Fragment } from 'react';
import ButtonMain from '../buttons/button-main';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import InputMain from '../commons/text-field-main';
import { useAppSelector } from '../../redux/selectors/app-selectors';
import { Formik, Form, useFormik } from 'formik';
import * as yup from 'yup';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import VerifyStepOne from '../../components/credit-cards/vefiry-step-one';
import VerifyStepTwo from '../credit-cards/vefiry-step-two';
import { creditCardService } from '../../api/services';
import { actionAlertNotification } from '../../redux/actions/alert-notifications';
import { creditCardInterface } from '../../interfaces/credit-cards';
import SlideDialog from '../modals/slide-dialog';
import { S3_ROOT_PATH, LINK_WHATSAPP } from '../../utils/constants';
import CloseIcon from '../../icons/close';
import { useNavigate } from 'react-router-dom';

type VerificationFailedTypes = {
    setVerificationFailed(b: boolean): void;
};

const VerificationFailed = ({
    setVerificationFailed,
}: VerificationFailedTypes) => {
    const navigate = useNavigate();

    const selector = useAppSelector((state) => state);

    const establishmentBranch =
        selector.rootReducer.establishment_branch.establishment_branch;

    return (
        <div>
            <nav
                className="flex justify-end px-4 pt-4"
                onClick={() => {
                    setVerificationFailed(false);
                    navigate(`/apparta?branchId=${establishmentBranch.id}`);
                }}
            >
                <div>
                    <CloseIcon />
                </div>
            </nav>
            <div className="flex justify-center">
                <div className="overflow-hidden ml-4 align-middle">
                    <img
                        className="inline-block rounded-md ring-2 ring-white w-[200px]"
                        src={`${S3_ROOT_PATH}/appartaPay/images/PAYMENT-FAILED.png`}
                        alt=""
                    />
                </div>
            </div>
            <div className="flex justify-center items-center flex-col">
                <div className="flex justify-center items-center flex-col">
                    <h4 className="font-medium">¡Agotaste tus intentos!</h4>
                    <p className="px-8 mt-3 text-sm">
                        Para continuar con la verificación de tu tarjeta,
                        contáctate con soporte.
                    </p>
                </div>
            </div>
            <div className="flex justify-center mt-5 mb-8">
                <ButtonMain
                    description="Contactar"
                    onClick={() => window.open(LINK_WHATSAPP, '_blank')}
                    className="bg-purple-main px-14"
                />
            </div>
        </div>
    );
};

function onlyLetter(e: any) {
    const keyCode = !e.charCode ? e.which : e.charCode;
    const regex = new RegExp('^[a-zA-Z ]+$');
    const key = String.fromCharCode(keyCode);
    if (keyCode !== 8 && !regex.test(key)) {
        e.preventDefault();
        return false;
    }
}

function onlyNumbers(e: any) {
    const keyCode = !e.charCode ? e.which : e.charCode;
    const regex = new RegExp('^[0-9 ]+$');
    const key = String.fromCharCode(keyCode);
    if (keyCode !== 8 && !regex.test(key)) {
        e.preventDefault();
        return false;
    }
}

const FormCreditCard = ({}: any) => {
    const [openVerifyStepOne, setOpenVerifyStepOne] = useState<boolean>(false);
    const [openVerifyStepTwo, setOpenVerifyStepTwo] = useState<boolean>(false);
    const [openVerificationFailed, setVerificationFailed] =
        useState<boolean>(false);

    const [creditCard, setCreditCard] = useState<creditCardInterface>({
        id: null,
    });
    const dispatch = useDispatch();

    const handleCloseVerifyStepOne = () => setOpenVerifyStepOne(false);
    const handleCloseVerifyStepTwo = () => setOpenVerifyStepTwo(false);

    const [isLoading, setIsLoading] = useState<boolean>();

    const paidVerification = () => {
        setTimeout(() => {
            setOpenVerifyStepOne(false);
            setOpenVerifyStepTwo(true);
        }, 3000);
    };

    const numberMask = createNumberMask({
        prefix: '-',
        thousandsSeparatorSymbol: '-',
        integerLimit: 4,
    });

    function CreditMaskCustom(props: any) {
        const { inputRef, ...other } = props;
        return (
            <MaskedInput
                {...other}
                ref={(ref: any) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={numberMask}
                placeholderChar={'\u2000'}
            />
        );
    }

    const selector = useAppSelector((state) => state);

    const user = selector.rootReducer.auth.user;

    function onlyLetter(e: any) {
        const keyCode = !e.charCode ? e.which : e.charCode;
        const regex = new RegExp('^[a-zA-Z ]+$');
        const key = String.fromCharCode(keyCode);
        if (keyCode !== 8 && !regex.test(key)) {
            e.preventDefault();
            return false;
        }
    }

    const validationSchema = yup.object({
        number: yup
            .string()
            .min(1)
            .max(16)
            .required('Debes poner el numero de la tarjeta'),
        expiry: yup
            .string()
            .min(1)
            .max(4)
            .required('Debes poner la fecha de expiración'),
        cvc: yup.string().min(1).max(4).required('Debes el cvv'),
        name: yup.string().min(1).max(40).required('Debes poner tu nombre'),
        phone: yup
            .string()
            .min(1)
            .max(16)
            .required('Debes poner el numero de telefono'),
        email: yup.string().email('Invalid email').required('Required'),
        documentType: yup.string(),
        identification_number: yup
            .string()
            .required('Debes poner el numero de la tarjeta.'),
    });

    return (
        <div className="pb-30">
            <div className="text-sm text-left pt-2 pb-3">
                Ingresa los datos de tu tarjeta para agregarla como nuevo método
                de pago
            </div>

            <Formik
                initialValues={{
                    masked_number: '4242424242424242',
                    expiry: '23/09',
                    cvc: '123',
                    name: 'Leonel Gomez Gomez',
                    phone: '3008559528',
                    email: 'inf.leonel@gmail.com',
                    type_document: 'CC',
                    identification_number: '1044427792',
                    term: false,
                    expiry_month: '',
                }}
                onSubmit={(values) => {
                    setIsLoading(true);
                    const expiry = values.expiry;

                    const dataCreditCard = {
                        owner_name: values.name,
                        identification_number: values.identification_number,
                        masked_number: values.masked_number,
                        type_document: values.type_document,
                        cvc: values.cvc,
                        cell_phone: values.phone,
                        exp_year: expiry.split('/')[0],
                        exp_month: expiry.split('/')[1],
                    };

                    creditCardService
                        .create(dataCreditCard)
                        .then((it: any) => {
                            setCreditCard(it);
                            setOpenVerifyStepOne(true);
                        })
                        .catch((e: any) => {
                            dispatch(
                                actionAlertNotification({
                                    TYPE: 'SET_ALERT_NOTIFICATIONS',
                                    params: {
                                        open: true,
                                        description:
                                            'Error al crear la tarejeta de credito',
                                        severity: 'error',
                                    },
                                })
                            );
                            setIsLoading(false);
                        });
                }}
                // validationSchema={validationSchema}
                // validateOnChange={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Cards
                            cvc={values.cvc}
                            expiry={values.expiry}
                            focused={'name'}
                            name={values.name}
                            number={values.masked_number}
                        />
                        <div className="text-left">
                            <div className="font-semibold text-left text-md pt-4 py-6 text-left">
                                Datos de la tarjeta
                            </div>
                            <div className="flex">
                                <div className="flex flex-col w-3/6 mr-4">
                                    <div
                                        className={`text-sm pb-2 ${
                                            errors?.masked_number &&
                                            touched.masked_number &&
                                            'text-red-700'
                                        }`}
                                    >
                                        Número de la tarjeta
                                    </div>
                                    <InputMain
                                        inputProps={{
                                            inputComponent: CreditMaskCustom,
                                        }}
                                        name="masked_number"
                                        onChange={handleChange}
                                        value={values.masked_number}
                                        placeHolder="0000 0000 0000 0000"
                                        className={'border-0.5 pl-1'}
                                        focus={true}
                                        autoComplete={'off'}
                                        onKeyDown={onlyNumbers}

                                        // onFocus={handleInputFocus}
                                    />
                                </div>
                                <div className="flex w-3/6">
                                    <div className="mr-4">
                                        <div
                                            className={`text-sm pb-2 ${
                                                errors?.expiry &&
                                                touched.expiry &&
                                                'text-red-700'
                                            }`}
                                        >
                                            MM/AA
                                        </div>
                                        <InputMain
                                            onChange={handleChange}
                                            value={values.expiry}
                                            name="expiry"
                                            placeHolder="00/00"
                                            className={'border-1 '}
                                            focus={true}
                                            autoComplete={'off'}
                                            onKeyDown={onlyNumbers}

                                            // onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div>
                                        <div
                                            className={`text-sm pb-2 ${
                                                errors?.cvc &&
                                                touched.cvc &&
                                                'text-red-700'
                                            }`}
                                        >
                                            CVC
                                        </div>
                                        <InputMain
                                            id="cvc"
                                            name="cvc"
                                            onChange={handleChange}
                                            value={values.cvc}
                                            placeHolder="***"
                                            className={'border-1 '}
                                            focus={true}
                                            autoComplete={'off'}
                                            onKeyDown={onlyNumbers}

                                            // onFocus={handleInputFocus}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div
                                    className={`text-sm pb-2 text-left pt-4 ${
                                        errors?.name &&
                                        touched.name &&
                                        'text-red-700'
                                    }`}
                                >
                                    Nombre del titular de la tarjeta
                                </div>
                                <InputMain
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    placeHolder="Ingresa el nombre en la tarjeta"
                                    className={'border-1'}
                                    // focus={true}
                                    onKeyDown={onlyLetter}
                                    // onFocus={handleInputFocus}
                                    autoComplete={'off'}
                                />
                            </div>
                            <div className="flex pt-4 w-full">
                                <div className="mr-4">
                                    <div
                                        className={`text-sm pb-2 ${
                                            errors?.type_document &&
                                            touched.type_document &&
                                            'text-red-700'
                                        }`}
                                    >
                                        Tipo de documento
                                    </div>
                                    <Select
                                        style={{
                                            borderRadius: '30px',
                                            height: '50px',
                                            borderColor: 'red',
                                        }}
                                        className="w-full"
                                        labelId="demo-customized-select-label"
                                        id="demo-customized-select"
                                        value={values.type_document}
                                        name="type_document"
                                        onChange={handleChange}
                                    >
                                        {[
                                            'CC',
                                            'CE',
                                            'PPN',
                                            'SSN',
                                            'LIC',
                                            'NIT',
                                            'TI',
                                        ].map((it, index) => (
                                            <MenuItem
                                                value={it}
                                                key={`selectItem${index}`}
                                            >
                                                <em>{it}</em>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="w-1/2">
                                    <div
                                        className={`text-sm pb-2 ${
                                            errors?.identification_number &&
                                            touched.identification_number &&
                                            'text-red-700'
                                        }`}
                                    >
                                        Número de documento
                                    </div>
                                    <InputMain
                                        onChange={handleChange}
                                        value={values.identification_number}
                                        type="text"
                                        name="identification_number"
                                        placeHolder="Numero de documento"
                                        className={'border-1 '}
                                        focus={true}
                                        onKeyDown={onlyNumbers}
                                        // onFocus={handleInputFocus}
                                        autoComplete={'off'}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col">
                                    <label className="font-semibold mb-2 mt-2">
                                        Tus datos
                                    </label>
                                    <label className="text-sm">
                                        Estos datos te los pediremos la primera
                                        vez que agregues este tipo de método
                                        para realizar tus pagos
                                    </label>
                                </div>

                                <div>
                                    <div>
                                        <div
                                            className={`text-sm pb-2 pt-8 ${
                                                errors?.email &&
                                                touched.email &&
                                                'text-red-700'
                                            }`}
                                        >
                                            Correo electrónico
                                        </div>
                                        <InputMain
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            placeHolder="Ingresa tu correo electronico"
                                            className={'border-1 '}
                                            focus={true}
                                            // onFocus={handleInputFocus}
                                            autoComplete={'off'}
                                        />
                                    </div>
                                    <div>
                                        <div
                                            className={`ttext-sm pb-2 pt-3 ${
                                                errors?.phone &&
                                                touched.phone &&
                                                'text-red-700'
                                            }`}
                                        >
                                            Número de celular
                                        </div>
                                        <InputMain
                                            onChange={handleChange}
                                            value={values.phone}
                                            type="text"
                                            name="phone"
                                            placeHolder="Ingresa tu numero de celular"
                                            className={'border-1 '}
                                            focus={true}
                                            // onFocus={handleInputFocus}
                                            autoComplete={'off'}
                                            onKeyDown={onlyNumbers}
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm pb-2">
                                            <Checkbox
                                                onChange={handleChange}
                                                value={values.term}
                                                name="term"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 28,
                                                    },
                                                }}
                                            />
                                            <label className="text-purple-main underline underline-offset-1">
                                                Acepto las políticas de
                                                tratamiento de datos
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 flex justify-center mb-[30px]">
                                    <ButtonMain
                                        disabled={
                                            (Object.keys(errors).length >= 0 &&
                                                values.masked_number === '') ||
                                            !values.term ||
                                            isLoading
                                        }
                                        className="bg-purple-main w-[180px] justify-center text-white"
                                        type="submit"
                                        description={'continuar'}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            <VerifyStepOne
                creditCard={creditCard}
                open={openVerifyStepOne}
                handleClose={() => handleCloseVerifyStepOne()}
                setOpenVerifyStepTwo={setOpenVerifyStepTwo}
            />
            <VerifyStepTwo
                creditCard={creditCard}
                open={openVerifyStepTwo}
                handleClose={() => handleCloseVerifyStepTwo()}
                setVerificationFailed={setVerificationFailed}
            />
            <SlideDialog
                children={
                    <VerificationFailed
                        setVerificationFailed={setVerificationFailed}
                    />
                }
                open={openVerificationFailed}
                setOpenModal={() => {}}
            />
        </div>
    );
};

export default FormCreditCard;
