import { combineReducers } from 'redux';

import establishment_branch from './establishment-branch';
import configurations from './configurations';
import alertNotifications from './alert-notifications';
import auth from './auth';
import payAccount from './pay-account';
import paymentMethodSelected from './payment-method-selected';
import walletUser from './wallet-user';
import creditCards from './credit-cards';

export default combineReducers({
    establishment_branch,
    configurations,
    alertNotifications,
    auth,
    payAccount,
    paymentMethodSelected,
    walletUser,
    creditCards,
});
