import * as React from 'react';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

type switchTypes = {
    disabled: boolean;
    setPaymentWithWallet(arg: boolean): void;
};

const SwitchMain = ({ disabled, setPaymentWithWallet }: switchTypes) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentWithWallet(event.target.checked ? true : false);
    };

    return (
        <div>
            <Switch
                {...label}
                disabled={disabled}
                defaultChecked
                className="switch-main"
                onChange={(e) => handleChange(e)}
            />
        </div>
    );
};

export default SwitchMain;
