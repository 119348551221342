import React, { ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type buttonMainInterface = {
    Icon?: ReactNode;
    description?: string;
    onClick?(): void;
    className?: string;
    classNameDescription?: string;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    isLoading?: boolean;
};

const ButtonMain = ({
    Icon,
    description,
    onClick,
    className,
    classNameDescription,
    disabled,
    type,
    isLoading,
}: buttonMainInterface) => {
    return (
        <div>
            <button
                type={type ? type : 'button'}
                disabled={disabled}
                onClick={onClick}
                className={`h-10 rounded-3xl text-white px-5 flex flex-row items-center py-0 ${className} ${
                    disabled ? 'bg-gray-400' : ''
                }`}
            >
                {Icon && !isLoading && <div className="mr-3">{Icon}</div>}
                {isLoading && (
                    <div className="mr-3">
                        {
                            <CircularProgress
                                size={16}
                                style={{
                                    color: 'white',
                                    marginBottom: -1,
                                }}
                            />
                        }
                    </div>
                )}
                <div className={`text-sm ${classNameDescription}`}>
                    {description && description}
                </div>
            </button>
        </div>
    );
};

export default ButtonMain;
