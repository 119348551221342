import React, { ReactNode, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../redux/selectors/app-selectors';
import { actionAlertNotification } from '../../redux/actions/alert-notifications';
import { useDispatch } from 'react-redux';
import resolveConfig from 'tailwindcss/resolveConfig';

type alertButtonInterface = {
    open: boolean;
    Icon?: ReactNode;
    description?: string;
    onClick?(): void;
    setOpen(b: boolean): void;
};

export type ErrorInterface = {
    open: boolean;
    Icon?: ReactNode;
    description?: string;
    onClick?(): void;
    setOpen(b: boolean): void;
    severity?: 'success' | 'info' | 'warning' | 'error';
};

const CustomAlert = ({ Icon, setOpen }: alertButtonInterface) => {
    const selector = useAppSelector((state) => state);

    const { open, description, severity }: ErrorInterface =
        selector.rootReducer.alertNotifications.error;

    const dispatch = useDispatch();

    useEffect(() => {}, [open]);

    return (
        <div className="w-full mt-16 px-4 fixed" style={{ zIndex: 100000 }}>
            <Collapse in={open}>
                <Alert
                    icon={false}
                    className="bg-purple-main"
                    severity={`${severity ? severity : 'error'}`}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(
                                    actionAlertNotification({
                                        TYPE: 'SET_ALERT_NOTIFICATIONS',
                                        params: { open: false },
                                    })
                                );
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {description}
                </Alert>
            </Collapse>
        </div>
    );
};

export default CustomAlert;
