import React from 'react';

const PaymentInProgress = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
        >
            <g
                id="Icon_feather-loader"
                data-name="Icon feather-loader"
                transform="translate(-1.5 -1.5)"
            >
                <path
                    id="Trazado_913"
                    data-name="Trazado 913"
                    d="M17.136,5.318a.636.636,0,0,1-.636-.636V2.136a.636.636,0,0,1,1.273,0V4.682A.636.636,0,0,1,17.136,5.318Z"
                    transform="translate(-8.636)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_914"
                    data-name="Trazado 914"
                    d="M17.136,29.318a.636.636,0,0,1-.636-.636V26.136a.636.636,0,1,1,1.273,0v2.545A.636.636,0,0,1,17.136,29.318Z"
                    transform="translate(-8.636 -13.818)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_915"
                    data-name="Trazado 915"
                    d="M8.332,8.969a.634.634,0,0,1-.45-.186l-1.8-1.8a.636.636,0,0,1,.9-.9l1.8,1.8a.636.636,0,0,1-.45,1.086Z"
                    transform="translate(-2.53 -2.53)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_916"
                    data-name="Trazado 916"
                    d="M25.3,25.934a.634.634,0,0,1-.45-.186l-1.8-1.8a.636.636,0,1,1,.9-.9l1.8,1.8a.636.636,0,0,1-.45,1.086Z"
                    transform="translate(-12.298 -12.298)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_917"
                    data-name="Trazado 917"
                    d="M4.682,17.773H2.136a.636.636,0,0,1,0-1.273H4.682a.636.636,0,0,1,0,1.273Z"
                    transform="translate(0 -8.636)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_918"
                    data-name="Trazado 918"
                    d="M28.682,17.773H26.136a.636.636,0,1,1,0-1.273h2.545a.636.636,0,1,1,0,1.273Z"
                    transform="translate(-13.818 -8.636)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_919"
                    data-name="Trazado 919"
                    d="M6.531,25.934a.636.636,0,0,1-.45-1.086l1.8-1.8a.636.636,0,1,1,.9.9l-1.8,1.8A.634.634,0,0,1,6.531,25.934Z"
                    transform="translate(-2.53 -12.298)"
                    fill="#ccc"
                />
                <path
                    id="Trazado_920"
                    data-name="Trazado 920"
                    d="M23.5,8.969a.636.636,0,0,1-.45-1.086l1.8-1.8a.636.636,0,0,1,.9.9l-1.8,1.8A.634.634,0,0,1,23.5,8.969Z"
                    transform="translate(-12.298 -2.53)"
                    fill="#ccc"
                />
            </g>
        </svg>
    );
};

export default PaymentInProgress;
