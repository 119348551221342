import React from 'react';

interface transformTypes {
    transform?: string;
}

const Ellipse = ({ transform }: transformTypes) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="390"
            height="25"
            viewBox="0 0 390 25"
            fill="#F7F7F7"
            transform={`${transform}`}
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectángulo_6561"
                        data-name="Rectángulo 6561"
                        width="390"
                        height="25"
                        transform="translate(0 172)"
                        fill="#fff"
                        stroke="#707070"
                        stroke-width="1"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect
                        id="Rectángulo_6525"
                        data-name="Rectángulo 6525"
                        width="390"
                        height="190"
                        transform="translate(0 39)"
                        fill="#fff"
                        stroke="#707070"
                        stroke-width="1"
                    />
                </clipPath>
            </defs>
            <g
                id="Enmascarar_grupo_67"
                data-name="Enmascarar grupo 67"
                transform="translate(0 -172)"
                clip-path="url(#clip-path)"
            >
                <g
                    id="Enmascarar_grupo_59"
                    data-name="Enmascarar grupo 59"
                    transform="translate(0 -39)"
                    clip-path="url(#clip-path-2)"
                >
                    <ellipse
                        id="Elipse_188"
                        data-name="Elipse 188"
                        cx="462"
                        cy="136"
                        rx="462"
                        ry="136"
                        transform="translate(-267 -43)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Ellipse;
