import { ClientFeathers } from './feathers';

export const establishmentBranchService = ClientFeathers.service(
    'establishments-branchs'
);

export const usersService = ClientFeathers.service('users');
export const payAccountService = ClientFeathers.service('pay-accounts');
export const walletUserService = ClientFeathers.service('wallet-users');
export const configurationService = ClientFeathers.service('configurations');
export const paymentsService = ClientFeathers.service('payments');
export const creditCardService = ClientFeathers.service('credit-cards');
export const creditCardVerifyService = ClientFeathers.service(
    'credit-cards-verify'
);
