type actionConfigurations = {
    TYPE: string;
    params: [any];
};

export function actionConfigurations({ TYPE, params }: actionConfigurations) {
    return {
        type: TYPE,
        payload: { ...params },
    };
}
