import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { S3_ROOT_PATH } from '../../utils/constants';
import Radio from '@mui/material/Radio';

import { useAppSelector } from '../../redux/selectors/app-selectors';

interface cardTypes {
    brand: string;
    checked: boolean;
    masked_number: string;
    defaultChecked?: boolean;
    onChange(creditCardId: number): void;
    creditCardId: number;
}

function CardCreditCard({
    brand,
    masked_number,
    checked,
    defaultChecked,
    creditCardId,
    onChange,
}: cardTypes) {
    useEffect(() => {}, []);

    const selector = useAppSelector((state) => state);

    const paymentMethodSelector =
        selector.rootReducer.paymentMethodSelected?.payment_method_selected
            ?.payment_method_selected;

    const paymentMethodAdditionalDataSelector =
        selector.rootReducer.paymentMethodSelected
            .payment_method_selected_additional_data;

    const handleClose = () => {
        setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState<boolean>(false);

    return (
        <div
            className={`flex flex-row w-full h-[60px] rounded-md items-center shadow-card-shadow `}
        >
            <div
                className="flex flex-row w-full items-center"
                onClick={(e) => onChange(creditCardId)}
            >
                <div className="ml-6">
                    <Radio
                        checked={checked}
                        // onChange={(e) => onChange(creditCardId)}
                        // onClick={() => onChange(creditCardId)}
                        value="a"
                        name="radio-buttons"
                        // defaultChecked={defaultChecked}
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </div>
                <div className="overflow-hidden mx-2 align-middle ">
                    <img
                        className="inline-block h-10 w-10 rounded-md ring-white"
                        src={`${S3_ROOT_PATH}/images/credit-cards-brands/${
                            brand || 'visa'
                        }.png`}
                        alt=""
                    />
                </div>
                <div className="text-md pr-2">Terminada en </div>
                <div> {masked_number.substring(masked_number.length - 4)}</div>
            </div>

            {/*<div className="pr-2" onClick={() => {}}>*/}
            {/*    <ArrowForwardIosIcon fontSize={'small'} />*/}
            {/*</div>*/}
        </div>
    );
}

export default CardCreditCard;
