const INITIAL_STATE = {
    user: {},
    isAuth: false,
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload,
                isAuth: action.payload.isAuth,
            };
        default:
            return { ...state };
    }
}
