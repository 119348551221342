import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
        >
            <g
                id="Grupo_17387"
                data-name="Grupo 17387"
                transform="translate(-16 -122)"
            >
                <g
                    id="Grupo_16217"
                    data-name="Grupo 16217"
                    transform="translate(16 122)"
                >
                    <circle
                        id="Elipse_145"
                        data-name="Elipse 145"
                        cx="21"
                        cy="21"
                        r="21"
                        fill="#f2f2f2"
                    />
                </g>
                <g
                    id="Grupo_16224"
                    data-name="Grupo 16224"
                    transform="translate(29.856 135.226)"
                >
                    <g
                        id="Grupo_16222"
                        data-name="Grupo 16222"
                        transform="translate(5.668 3.779)"
                    >
                        <path
                            id="Trazado_972"
                            data-name="Trazado 972"
                            d="M299.784,173.266c-.09-.033-.174-.063-.247-.093s-.161-.062-.257-.1c-.563-.2-1.029-.4-1.029-.836a.8.8,0,0,1,.514-.616,1.916,1.916,0,0,1,1.907.274h0a.393.393,0,0,0,.528-.076.344.344,0,0,0-.08-.5,3.074,3.074,0,0,0-1.357-.539v-.673a.378.378,0,0,0-.756,0v.691a2.277,2.277,0,0,0-.582.186,1.468,1.468,0,0,0-.929,1.252c0,.959.988,1.312,1.517,1.5.083.029.158.057.22.082.083.035.179.069.282.107.588.212,1,.4,1,.826h0a.8.8,0,0,1-.514.616,1.918,1.918,0,0,1-1.907-.274h0a.4.4,0,0,0-.281-.065.383.383,0,0,0-.248.141.344.344,0,0,0,.081.5,3.071,3.071,0,0,0,1.357.537v.711a.379.379,0,0,0,.756,0v-.729a2.284,2.284,0,0,0,.582-.184,1.468,1.468,0,0,0,.929-1.253c0-.954-.932-1.291-1.49-1.492Z"
                            transform="translate(-297.495 -169.755)"
                            fill="#6b24fa"
                        />
                    </g>
                    <g id="gira-a-la-derecha" transform="translate(0)">
                        <path
                            id="Trazado_975"
                            data-name="Trazado 975"
                            d="M15.278,7.581a.62.62,0,0,0-.625.553,6.337,6.337,0,1,1-1.794-4.975H10.886a.632.632,0,1,0,0,1.264H13.5a1.174,1.174,0,0,0,1.173-1.173V.632a.632.632,0,0,0-1.264,0V1.945a7.572,7.572,0,1,0,2.5,6.332.636.636,0,0,0-.575-.692Z"
                            transform="translate(-0.797)"
                            fill="#6b24fa"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Verified;
