import React, { useState } from 'react';
import ButtonMain from '../buttons/button-main';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { ClientFeathers } from '../../api/feathers';
import TextFieldMain from '../commons/text-field-main';
import { actionAlertNotification } from '../../redux/actions/alert-notifications';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { authenticate } from '../../redux/actions/auth';
import { actionPayAccount } from '../../redux/actions/pay-account';
import CloseIcon from '@mui/icons-material/Close';
type formSendOtpTypes = {
    setCurrentStep(b: boolean): void;
    sendCode(): void;
    handleChangeOtp(e: React.ChangeEvent<HTMLInputElement>): void;
};

const FormSendOtp = ({
    setCurrentStep,
    sendCode,
    handleChangeOtp,
}: formSendOtpTypes) => (
    <div>
        <div>
            <div className="font-bold font-m text-center">!Listo!</div>
            <div className="font-sm font-medium text-left">
                Ingresa el código que te enviamos al numero por Whatsapp/SMS
            </div>
        </div>
        <div>
            <div className="mt-4">
                <TextFieldMain
                    onChange={(e) => handleChangeOtp(e)}
                    name={'input-otp'}
                    placeHolder={'Escribe el codigo'}
                    type={'number'}
                    className={'text-center'}
                />
                <div className="mt-5 flex justify-center">
                    <ButtonMain
                        description="Continuar"
                        onClick={() => sendCode()}
                        className="bg-purple-main"
                    />
                </div>
                <div
                    className={'text-center mt-2'}
                    onClick={() => setCurrentStep(false)}
                >
                    {'< cambiar numero'}
                </div>
            </div>
        </div>
    </div>
);

export type AuthenticationResult = {
    [key: string]: any;
    user: any;
};

const Login = (props: any) => {
    type otpTypes = {
        strategy: string;
        phone: string;
        otp: number;
        countryCode: string;
    };

    type sentCodeTypes = {
        prefix: string;
        email: string;
        phone: string;
        otp: string;
    };

    const [otpData, setOtpData] = useState<otpTypes>({
        strategy: 'sms',
        phone: '',
        otp: 0,
        countryCode: '57',
    });
    const [currentStep, setCurrentStep] = useState<any>(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleChangeOtp = (e: any) => {
        setOtpData((prevState: any) => ({
            ...prevState,
            otp: e,
        }));
    };

    const handleChangePhone = ({
        phone,
        countryCode,
    }: {
        phone: string;
        countryCode: { dialCode: [] };
    }) => {
        setOtpData((prevState) => ({
            ...prevState,
            phone: phone.substr(countryCode.dialCode.length),
            phone_country_code: countryCode.dialCode,
        }));
    };

    const sendCode = () => {
        const { phone, otp, countryCode } = otpData;
        const currentPhone = phone.substr(countryCode.length);

        ClientFeathers.authenticate({
            strategy: 'sms',
            phone: currentPhone,
            phone_country_code: otpData.countryCode,
            token_login_phone: otp ? otp : null,
        })
            .then((res: any) => {
                if (res.name === 'send-token') {
                    setCurrentStep(1);
                }
                if (res.accessToken) {
                    navigate(`${location.pathname}`);

                    dispatch(
                        authenticate({
                            TYPE: 'SET_USER',
                            params: { ...res.user, isAuth: true },
                        })
                    );
                }
            })
            .catch((e: any) => {
                dispatch(
                    actionAlertNotification({
                        TYPE: 'SET_ALERT_NOTIFICATIONS',
                        params: {
                            open: true,
                            description: e.message,
                        },
                    })
                );
            });
    };

    return (
        <div className="shadow-card-shadow bg-white ">
            <div className="flex flex-col items-center px-4 py-6">
                <div
                    className="text-right w-full mt-[-22px]"
                    onClick={() => {
                        props.handleClose();
                    }}
                >
                    <CloseIcon />
                </div>
                {!currentStep && (
                    <>
                        <div className="font-bold font-m">!Hola!</div>
                        <div className="font-sm font-medium">
                            Enviaremos un código a tu whatsapp papra identificar
                            tu pedido.
                        </div>
                    </>
                )}
                <div className="mt-4 w-full">
                    {!currentStep && (
                        <PhoneInput
                            onlyCountries={['co']}
                            disableDropdown
                            autoFormat
                            defaultMask="+57"
                            inputStyle={{
                                marginBottom: 6,
                                marginTop: 6,
                                minWidth: 220,
                                borderRadius: 50,
                                width: '100%',
                                border: `2px solid gray`,
                            }}
                            masks={{ co: '(...) ... ....' }}
                            buttonStyle={{
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                            }}
                            containerClass="phone-input"
                            country="co"
                            placeholder={'Celular'}
                            specialLabel={''}
                            onChange={(e) =>
                                handleChangePhone({
                                    phone: e,
                                    countryCode: { dialCode: [] },
                                })
                            }
                        />
                    )}
                    {currentStep === 1 && (
                        <FormSendOtp
                            setCurrentStep={() => setCurrentStep(false)}
                            sendCode={() => sendCode()}
                            handleChangeOtp={(e) =>
                                handleChangeOtp(e.target.value)
                            }
                        />
                    )}
                </div>
                {!currentStep && (
                    <div className="mt-5">
                        <ButtonMain
                            description="Enviar código"
                            onClick={() => sendCode()}
                            className="bg-purple-main"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
