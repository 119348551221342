const INITIAL_STATE = {
    error: {
        open: false,
        Icon: '',
        description: '',
        onClick: '',
        setOpen: '',
    },
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_ALERT_NOTIFICATIONS':
            return {
                ...state,
                error: { ...action.payload },
            };
        default:
            return { ...state };
    }
}
