import React, { ReactNode, useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PAYMENT_METHOD_ASSETS, S3_ROOT_PATH } from '../../utils/constants';
import { paymentMethodInterface } from '../../interfaces/payments-methods';
import numeral from 'numeral';
import SwitchMain from '../switch/switch-main';
import SlideDialog from '../modals/slide-dialog';
import PaymentWithNequiForm from '../forms/payment-nequi';
import EditIcon from '@mui/icons-material/Edit';

import { useAppSelector } from '../../redux/selectors/app-selectors';

interface cardTypes {
    type: string;
    class_text_color?: string;
    bg?: string;
    primary_text: string;
    second_text?: string;
    arrowIcon?: boolean;
    haveSwitch?: boolean;
    amount?: number;
    allow_payments_with_credit_wallet?: boolean;
    setPaymentWithWallet?(arg: boolean): void;
    paymentWithWallet?: boolean;
}

function CardPaymentMethod({
    class_text_color,
    bg,
    primary_text,
    second_text,
    type,
    arrowIcon,
    haveSwitch,
    amount,
    allow_payments_with_credit_wallet = true,
    setPaymentWithWallet,
    paymentWithWallet,
}: cardTypes) {
    useEffect(() => {}, []);

    const selector = useAppSelector((state) => state);

    const paymentMethodSelector =
        selector.rootReducer.paymentMethodSelected?.payment_method_selected
            ?.payment_method_selected;

    const paymentMethodAdditionalDataSelector =
        selector.rootReducer.paymentMethodSelected
            .payment_method_selected_additional_data;

    const handleClose = () => {
        setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState<boolean>(false);

    return (
        <div
            className={`flex flex-row w-full h-[60px] rounded-md items-center shadow-card-shadow ${bg}`}
        >
            <div className="flex flex-row w-full">
                <div className="overflow-hidden ml-4 align-middle">
                    <img
                        className="inline-block h-10 w-10 rounded-md ring-white"
                        src={`${S3_ROOT_PATH}/${
                            PAYMENT_METHOD_ASSETS[
                                type as keyof paymentMethodInterface
                            ]
                        }`}
                        alt=""
                    />
                </div>
                <div className="flex flex-col ml-6 items-start align-bottom justify-center ">
                    <div className={`${class_text_color} `}>{primary_text}</div>
                    {second_text && (
                        <div className="text-xs text-purple-main underline underline-offset-1 font-semibold">
                            {second_text}
                        </div>
                    )}
                    {paymentMethodSelector === 'nequi' &&
                        type === 'nequi' &&
                        paymentMethodAdditionalDataSelector?.phone_number && (
                            <div className="flex align-middle items-baseline">
                                <div className="text-white text-xs">
                                    {
                                        paymentMethodAdditionalDataSelector?.phone_number
                                    }
                                </div>
                                <div className="text-white ml-1">
                                    <EditIcon
                                        sx={{
                                            fontSize: 16,
                                        }}
                                        onClick={() => setOpenModal(true)}
                                    />
                                </div>
                            </div>
                        )}
                    {!!amount && amount > 0 && (
                        <div className="text-green-main font-bold">
                            {numeral(amount).format('$0,0')}
                        </div>
                    )}
                </div>
            </div>
            {arrowIcon && (
                <div className={`pr-2 ${class_text_color}`}>
                    <ArrowForwardIosIcon fontSize={'small'} />
                </div>
            )}
            {haveSwitch && (
                <SwitchMain
                    disabled={!allow_payments_with_credit_wallet}
                    setPaymentWithWallet={(e) => {
                        if (setPaymentWithWallet) setPaymentWithWallet(e);
                    }}
                />
            )}
            <SlideDialog
                children={
                    <PaymentWithNequiForm handleClose={() => handleClose()} />
                }
                open={openModal}
                setOpenModal={setOpenModal}
            />
        </div>
    );
}

export default CardPaymentMethod;
