import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

type EstablishmentLogoType = {
    logoPath: string;
};

function EstablishmentLogo({ logoPath }: EstablishmentLogoType) {
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    }, []);

    const theme = useTheme();

    const getData = () => {};

    return (
        <div>
            <div className="mt-3 flex -space-x-2 overflow-hidden">
                <img
                    className="inline-block h-16 w-16 rounded-md ring-2 ring-white"
                    src={`${logoPath}`}
                    alt=""
                />
            </div>
        </div>
    );
}

export default EstablishmentLogo;
