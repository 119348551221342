import { SET_ESTABLISHMENT } from './types';

type actionEstablishment = {
    TYPE: string;
    params: object;
};

export function actionEstablishment({ TYPE, params }: actionEstablishment) {
    return {
        type: TYPE,
        payload: { ...params },
    };
}
