import React, { useEffect } from 'react';
import Ellipse from '../../icons/ellipse';
import { useAppSelector } from '../../redux/selectors/app-selectors';
import { useNavigate, useLocation } from 'react-router-dom';

function WelcomeTextEllipse(props: any) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const selector = useAppSelector((state) => state);

    const isAuth = selector.rootReducer.auth.isAuth;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="flex flex-col">
            <div className="bg-ellipse-bg">
                <div className="mt-2">
                    <div>¡Paga ahora fácil, rápido y seguro!</div>
                </div>
                <div className="bg-ellipse-bg text-xs px-4 mt-1 text-start">
                    {!isAuth && (
                        <div>
                            Podrás pagar como invitado o{' '}
                            <a
                                onClick={() =>
                                    !isAuth
                                        ? navigate(
                                              `${location.pathname}?Authentication=false`
                                          )
                                        : () => {}
                                }
                                className="text-blue-href decoration-current decoration-black"
                            >
                                ingresa aquí
                            </a>{' '}
                            para guardar tus datos y facilitar tus futuros pagos
                        </div>
                    )}
                </div>
            </div>
            <div>
                <Ellipse />
            </div>
        </div>
    );
}

export default WelcomeTextEllipse;
