type actionPaymentMethodTypes = {
    TYPE: string;
    params: object;
};

export function actionPaymentMethodSelected({
    TYPE,
    params,
}: actionPaymentMethodTypes) {
    return {
        type: TYPE,
        payload: { ...params },
    };
}
