import React from 'react';

const Bonos = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
        >
            <g
                id="Grupo_17388"
                data-name="Grupo 17388"
                transform="translate(-16 -122)"
            >
                <g
                    id="Grupo_16217"
                    data-name="Grupo 16217"
                    transform="translate(16 122)"
                >
                    <circle
                        id="Elipse_145"
                        data-name="Elipse 145"
                        cx="21"
                        cy="21"
                        r="21"
                        fill="#f2f2f2"
                    />
                </g>
                <g
                    id="Grupo_1453"
                    data-name="Grupo 1453"
                    transform="translate(-1374.55 5187.988)"
                >
                    <path
                        id="Trazado_844"
                        data-name="Trazado 844"
                        d="M85.382,118.324a.746.746,0,1,1,.745-.746A.747.747,0,0,1,85.382,118.324Zm0-2.276a1.53,1.53,0,1,0,1.53,1.53A1.532,1.532,0,0,0,85.382,116.049Zm-3.215-2.43a.746.746,0,1,1-.746.746A.746.746,0,0,1,82.167,113.619Zm0,2.276a1.53,1.53,0,1,0-1.53-1.53A1.532,1.532,0,0,0,82.167,115.894Zm4.188-1.949a.392.392,0,1,0-.555-.554L81.193,118a.392.392,0,0,0,.555.555ZM75.419,119.3a.392.392,0,0,1-.392.392H73.306a.392.392,0,0,1,0-.784h1.721A.392.392,0,0,1,75.419,119.3Zm1.832-2.424v1.591a.392.392,0,1,1-.784,0v-1.591a.392.392,0,1,1,.784,0Zm0-3.216v1.591a.392.392,0,1,1-.784,0v-1.591a.392.392,0,0,1,.784,0ZM75.419,112.3a.392.392,0,0,1-.392.392H73.306a.392.392,0,0,1,0-.784h1.721A.392.392,0,0,1,75.419,112.3ZM90.3,117.787a1.819,1.819,0,0,1,0-3.631v-3.5H77.251v1.373a.392.392,0,1,1-.784,0v-1.373h-4.6v3.5a1.819,1.819,0,0,1,0,3.631v3.5h4.6v-1.2a.392.392,0,1,1,.784,0v1.2H90.3v-3.5Zm.109-2.85h.283a.392.392,0,0,0,.392-.392v-4.281a.392.392,0,0,0-.392-.392H71.473a.392.392,0,0,0-.392.392v4.281a.392.392,0,0,0,.392.392h.284a1.035,1.035,0,1,1,0,2.069h-.284a.392.392,0,0,0-.392.392v4.281a.392.392,0,0,0,.392.392H90.689a.392.392,0,0,0,.392-.392V117.4a.392.392,0,0,0-.392-.392h-.283a1.035,1.035,0,1,1,0-2.069Z"
                        transform="translate(1330.469 -5160.959)"
                        fill="#6b24fa"
                        fill-rule="evenodd"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Bonos;
