import { ReactNode } from 'react';

type actionAlertNotificationTypes = {
    TYPE: string;
    params: {
        open: boolean;
        Icon?: ReactNode;
        description?: string;
        onClick?(): void;
        setOpen?(b: boolean): void;
        severity?: 'success' | 'info' | 'warning' | 'error';
    };
};

export function actionAlertNotification({
    TYPE,
    params,
}: actionAlertNotificationTypes) {
    return {
        type: TYPE,
        payload: { ...params },
    };
}
