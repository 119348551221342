const INITIAL_STATE = {
    credit_cards: [],
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_CREDIT_CARDS':
            return {
                ...state,
                credit_cards: action.payload,
            };
        default:
            return { ...state };
    }
}
