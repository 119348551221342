type actionPayAccount = {
    TYPE: string;
    params: {};
};

export function actionWalletUser({ TYPE, params }: actionPayAccount) {
    return {
        type: TYPE,
        payload: { ...params },
    };
}
