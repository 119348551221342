const INITIAL_STATE = {
    payment_method_selected: null,
    payment_method_selected_additional_data: null,
};

export default function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case 'SET_PAYMENT_METHOD_SELECTED':
            return {
                ...state,
                payment_method_selected: { ...action.payload },
            };
        case 'SET_DATA_ADDITIONAL_PAYMENT':
            return {
                ...state,
                payment_method_selected_additional_data: { ...action.payload },
            };
        default:
            return { ...state };
    }
}
