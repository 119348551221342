import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
        >
            <g
                id="Agregar_métodos"
                data-name="Agregar métodos"
                transform="translate(-172.5 -591.5)"
            >
                <g
                    id="Grupo_17118"
                    data-name="Grupo 17118"
                    transform="translate(172.5 591.5)"
                >
                    <path
                        id="Trazado_1218"
                        data-name="Trazado 1218"
                        d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
                        transform="translate(0 0)"
                        fill="#f2f2f2"
                    />
                </g>
                <g
                    id="Grupo_17132"
                    data-name="Grupo 17132"
                    transform="translate(179.5 598.5)"
                >
                    <path
                        id="Trazado_907"
                        data-name="Trazado 907"
                        d="M25.31,17.31h-5.6v-5.6a1.2,1.2,0,1,0-2.4,0v5.6h-5.6a1.151,1.151,0,0,0-1.2,1.2,1.163,1.163,0,0,0,1.2,1.2h5.6v5.6a1.164,1.164,0,0,0,1.2,1.2,1.2,1.2,0,0,0,1.2-1.2v-5.6h5.6a1.2,1.2,0,0,0,0-2.4Z"
                        transform="translate(-10.512 -10.512)"
                        fill="#6b24f8"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Verified;
