import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { checkoutRoutes, creditCardRoutes } from './routes';
import { Main } from './layouts';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import SlideDialog from './components/modals/slide-dialog';
import LoginForm from './components/forms/login';
import Alert from './components/alerts/Alert';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { retryLoginAndGetCurrentUser } from './redux/actions/auth';

function App() {
    const params = useParams();

    useEffect(() => {
        store.dispatch(retryLoginAndGetCurrentUser());
    }, []);

    useEffect(() => {
        console.log(store.getState(), 'selector store');
    }, []);

    const routes = [...checkoutRoutes, ...creditCardRoutes];

    return (
        <div className="App">
            <Provider store={store}>
                <Alert open={true} setOpen={() => {}} />
                <Router>
                    <Routes>
                        {routes.map((route, index) => (
                            <Route
                                path={route.path}
                                element={<Main children={route.component} />}
                            />
                        ))}
                    </Routes>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
