import { retryLogin } from '../../api/feathers';
import { payAccountService, walletUserService } from '../../api/services';
import { actionPayAccount } from '../actions/pay-account';
import { actionWalletUser } from '../actions/wallet-user';

export const retryLoginAndGetCurrentUser = () => (dispatch: any) => {
    if (window.localStorage.getItem('feathers-jwt')) {
        return retryLogin().then((it: any) => {
            const { user } = it;

            dispatch({ type: 'SET_USER', payload: { ...user, isAuth: true } });

            walletUserService.find({}).then((it: any) => {
                const { data } = it;
                dispatch(
                    actionWalletUser({
                        TYPE: 'SET_WALLET_USER',
                        params: { ...data[0] },
                    })
                );
            });
        });
    }
};

type loginTypes = {
    TYPE: string;
    params: {};
};

export function authenticate({ TYPE, params }: loginTypes) {
    console.log(TYPE);
    console.log(params, 'params');
    return {
        type: TYPE,
        payload: { ...params },
    };
}
