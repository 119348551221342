import React from 'react';

const Verified = (props: any) => {
    return (
        <svg
            id="Grupo_16170"
            data-name="Grupo 16170"
            xmlns="http://www.w3.org/2000/svg"
            width="140"
            height="36"
            viewBox="0 0 140 36"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="Trazado_870"
                        data-name="Trazado 870"
                        d="M556.938,167.711a4.463,4.463,0,0,1-1.6-1.617,4.637,4.637,0,0,1,0-4.522,4.556,4.556,0,0,1,1.6-1.617,4.5,4.5,0,0,1,4.473,0,4.654,4.654,0,0,1,1.6,1.617,4.632,4.632,0,0,1,0,4.522,4.556,4.556,0,0,1-1.6,1.617,4.424,4.424,0,0,1-4.473,0m-1.13-9.738a6.855,6.855,0,0,0-2.429,2.455,6.8,6.8,0,0,0,0,6.809,6.886,6.886,0,0,0,2.429,2.466,6.669,6.669,0,0,0,5.772.463,6.553,6.553,0,0,0,2.02-1.253v.975a.724.724,0,0,0,.72.727h.844a.724.724,0,0,0,.72-.728v-6.055a6.728,6.728,0,0,0-.926-3.417,6.881,6.881,0,0,0-2.417-2.443,6.6,6.6,0,0,0-6.733,0"
                        transform="translate(-552.466 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path
                        id="Trazado_871"
                        data-name="Trazado 871"
                        d="M769.572,167.711a4.465,4.465,0,0,1-1.6-1.617,4.632,4.632,0,0,1,0-4.522,4.558,4.558,0,0,1,1.6-1.617,4.5,4.5,0,0,1,4.473,0,4.652,4.652,0,0,1,1.6,1.617,4.632,4.632,0,0,1,0,4.522,4.555,4.555,0,0,1-1.6,1.617,4.425,4.425,0,0,1-4.473,0m-1.13-9.738a6.886,6.886,0,0,0-2.417,2.443,6.692,6.692,0,0,0-.926,3.4V174.5a.724.724,0,0,0,.72.728h.845a.723.723,0,0,0,.719-.728v-5.582a6.631,6.631,0,0,0,7.792.79,6.879,6.879,0,0,0,2.429-2.466,6.8,6.8,0,0,0,0-6.809,6.848,6.848,0,0,0-2.429-2.455,6.435,6.435,0,0,0-3.367-.924,6.322,6.322,0,0,0-3.367.924"
                        transform="translate(-765.099 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-3">
                    <path
                        id="Trazado_872"
                        data-name="Trazado 872"
                        d="M982.205,167.711a4.462,4.462,0,0,1-1.6-1.617,4.637,4.637,0,0,1,0-4.522,4.555,4.555,0,0,1,1.6-1.617,4.5,4.5,0,0,1,4.472,0,4.66,4.66,0,0,1,1.6,1.617,4.631,4.631,0,0,1,0,4.522,4.562,4.562,0,0,1-1.6,1.617,4.424,4.424,0,0,1-4.472,0m-1.13-9.738a6.879,6.879,0,0,0-2.417,2.443,6.682,6.682,0,0,0-.926,3.4V174.5a.725.725,0,0,0,.72.728h.845a.725.725,0,0,0,.72-.728v-5.582a6.63,6.63,0,0,0,7.791.79,6.876,6.876,0,0,0,2.428-2.466,6.8,6.8,0,0,0,0-6.809,6.845,6.845,0,0,0-2.428-2.455,6.434,6.434,0,0,0-3.366-.924,6.321,6.321,0,0,0-3.367.924"
                        transform="translate(-977.732 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-4">
                    <path
                        id="Trazado_873"
                        data-name="Trazado 873"
                        d="M1194.847,167.711a4.458,4.458,0,0,1-1.6-1.617,4.633,4.633,0,0,1,0-4.522,4.551,4.551,0,0,1,1.6-1.617,4.5,4.5,0,0,1,4.473,0,4.658,4.658,0,0,1,1.6,1.617,4.631,4.631,0,0,1,0,4.522,4.56,4.56,0,0,1-1.6,1.617,4.424,4.424,0,0,1-4.473,0m-1.13-9.738a6.85,6.85,0,0,0-2.429,2.455,6.8,6.8,0,0,0,0,6.809,6.881,6.881,0,0,0,2.429,2.466,6.668,6.668,0,0,0,5.771.463,6.557,6.557,0,0,0,2.02-1.253v.975a.723.723,0,0,0,.719.728h.845a.723.723,0,0,0,.719-.728v-6.055a6.719,6.719,0,0,0-.926-3.417,6.88,6.88,0,0,0-2.416-2.443,6.6,6.6,0,0,0-6.733,0"
                        transform="translate(-1190.374 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-5">
                    <path
                        id="Trazado_874"
                        data-name="Trazado 874"
                        d="M1403.735,157.05a.723.723,0,0,0-.72.728v12.11a.723.723,0,0,0,.72.728h.844a.724.724,0,0,0,.72-.728v-6.055a4.4,4.4,0,0,1,.589-2.262,4.56,4.56,0,0,1,1.6-1.617,4.156,4.156,0,0,1,1.615-.556.722.722,0,0,0,.622-.72v-.857a.724.724,0,0,0-.8-.725,6.536,6.536,0,0,0-3.621,1.654v-.974a.724.724,0,0,0-.72-.728Z"
                        transform="translate(-1403.015 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-6">
                    <path
                        id="Trazado_875"
                        data-name="Trazado 875"
                        d="M1529.757,98.222a.724.724,0,0,0-.709.728v10.7a6.831,6.831,0,0,0,.926,3.4,6.943,6.943,0,0,0,2.417,2.43,6.357,6.357,0,0,0,2.57.868.723.723,0,0,0,.8-.726v-.85a.72.72,0,0,0-.617-.719,4.247,4.247,0,0,1-1.619-.566,4.6,4.6,0,0,1-1.588-1.605,4.482,4.482,0,0,1-.6-2.261v-1.167h3.705a.724.724,0,0,0,.72-.728v-.841a.724.724,0,0,0-.72-.728h-3.705V98.95a.724.724,0,0,0-.709-.728Z"
                        transform="translate(-1529.048 -98.222)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-7">
                    <path
                        id="Trazado_876"
                        data-name="Trazado 876"
                        d="M1659.552,167.711a4.457,4.457,0,0,1-1.6-1.617,4.632,4.632,0,0,1,0-4.522,4.55,4.55,0,0,1,1.6-1.617,4.5,4.5,0,0,1,4.472,0,4.654,4.654,0,0,1,1.6,1.617,4.636,4.636,0,0,1,0,4.522,4.556,4.556,0,0,1-1.6,1.617,4.423,4.423,0,0,1-4.472,0m-1.13-9.738a6.853,6.853,0,0,0-2.429,2.455,6.8,6.8,0,0,0,0,6.809,6.884,6.884,0,0,0,2.429,2.466,6.669,6.669,0,0,0,5.772.463,6.553,6.553,0,0,0,2.019-1.253v.975a.724.724,0,0,0,.72.728h.845a.724.724,0,0,0,.72-.728v-6.055a6.726,6.726,0,0,0-.927-3.417,6.883,6.883,0,0,0-2.416-2.443,6.6,6.6,0,0,0-6.733,0"
                        transform="translate(-1655.079 -157.05)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-8">
                    <path
                        id="Trazado_877"
                        data-name="Trazado 877"
                        d="M27.734,10.446a3.522,3.522,0,0,1,3.529-3.782,3.307,3.307,0,0,1,3.42,3.573,3.54,3.54,0,0,1-3.507,3.851,3.408,3.408,0,0,1-3.442-3.643m1.837-8.69A13.914,13.914,0,0,0,22.88,8.533c-3.366,7.316-1.514,14.528,2.986,19.047L33.6,18.747,42.3,9.04l-.128-.166a1.4,1.4,0,0,1,.059-1.763L46.04,2.762A15.772,15.772,0,0,0,37.107,0a17.612,17.612,0,0,0-7.536,1.755"
                        transform="translate(-21.153)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-9">
                    <path
                        id="Trazado_878"
                        data-name="Trazado 878"
                        d="M315.767,43.026l-3.02,3.447a.417.417,0,0,0-.1.314.408.408,0,0,0,.155.291.428.428,0,0,0,.585-.07l3.009-3.435c.009-.01.021-.019.03-.028q-.317-.276-.647-.534l-.009.015"
                        transform="translate(-312.643 -43.011)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-10">
                    <path
                        id="Trazado_879"
                        data-name="Trazado 879"
                        d="M333.461,58.54l-3.026,3.454a.422.422,0,0,0,.052.606.43.43,0,0,0,.585-.07l2.985-3.407q-.283-.31-.582-.6c-.005.007-.008.014-.014.02"
                        transform="translate(-330.331 -58.52)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-11">
                    <path
                        id="Trazado_880"
                        data-name="Trazado 880"
                        d="M107.689,92.921a3.522,3.522,0,0,1,3.53-3.782,3.307,3.307,0,0,1,3.419,3.573,3.54,3.54,0,0,1-3.507,3.852,3.408,3.408,0,0,1-3.442-3.643m6.182-12.366a1.359,1.359,0,0,1-1.724.271l-.174-.105L102.962,90.74l-.147.155.057.148a1.615,1.615,0,0,1-.475,1.673l-5.38,5.261A2.681,2.681,0,0,1,95,98.8q.4.341.828.655l7.576,6.181a2.9,2.9,0,0,0,3.666,0l7.589-6.19c.4-.3,1.175-.958,1.175-.958h0a16.258,16.258,0,0,0,1.8-22.224Z"
                        transform="translate(-94.997 -76.266)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-12">
                    <path
                        id="Trazado_881"
                        data-name="Trazado 881"
                        d="M137.436,109.817c0,1.146.359,1.917,1.083,1.917.661,0,1.01-.655,1.01-1.921,0-1.119-.285-1.931-1.028-1.931-.767,0-1.065.844-1.065,1.935"
                        transform="translate(-137.436 -107.882)"
                        fill="#6b24fa"
                    />
                </clipPath>
                <clipPath id="clip-path-13">
                    <path
                        id="Trazado_882"
                        data-name="Trazado 882"
                        d="M290.17,265.359c0,1.146.358,1.916,1.083,1.916.661,0,1.01-.655,1.01-1.921,0-1.119-.284-1.931-1.028-1.931-.766,0-1.065.844-1.065,1.935"
                        transform="translate(-290.17 -263.424)"
                        fill="#6b24fa"
                    />
                </clipPath>
            </defs>
            <g
                id="Grupo_1543"
                data-name="Grupo 1543"
                transform="translate(41.161 12.301)"
            >
                <g
                    id="Grupo_1542"
                    data-name="Grupo 1542"
                    clip-path="url(#clip-path)"
                >
                    <rect
                        id="Rectángulo_1299"
                        data-name="Rectángulo 1299"
                        width="13.418"
                        height="13.566"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1545"
                data-name="Grupo 1545"
                transform="translate(57.634 12.301)"
            >
                <g
                    id="Grupo_1544"
                    data-name="Grupo 1544"
                    clip-path="url(#clip-path-2)"
                >
                    <rect
                        id="Rectángulo_1300"
                        data-name="Rectángulo 1300"
                        width="13.419"
                        height="18.174"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1547"
                data-name="Grupo 1547"
                transform="translate(74.107 12.301)"
            >
                <g
                    id="Grupo_1546"
                    data-name="Grupo 1546"
                    clip-path="url(#clip-path-3)"
                >
                    <rect
                        id="Rectángulo_1301"
                        data-name="Rectángulo 1301"
                        width="13.419"
                        height="18.174"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1549"
                data-name="Grupo 1549"
                transform="translate(90.58 12.301)"
            >
                <g
                    id="Grupo_1548"
                    data-name="Grupo 1548"
                    clip-path="url(#clip-path-4)"
                >
                    <rect
                        id="Rectángulo_1302"
                        data-name="Rectángulo 1302"
                        width="13.418"
                        height="13.566"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1551"
                data-name="Grupo 1551"
                transform="translate(107.054 12.301)"
            >
                <g
                    id="Grupo_1550"
                    data-name="Grupo 1550"
                    clip-path="url(#clip-path-5)"
                >
                    <rect
                        id="Rectángulo_1303"
                        data-name="Rectángulo 1303"
                        width="6.709"
                        height="13.571"
                        transform="translate(0 -0.005)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1553"
                data-name="Grupo 1553"
                transform="translate(116.818 7.693)"
            >
                <g
                    id="Grupo_1552"
                    data-name="Grupo 1552"
                    clip-path="url(#clip-path-6)"
                >
                    <rect
                        id="Rectángulo_1304"
                        data-name="Rectángulo 1304"
                        width="6.709"
                        height="18.178"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1555"
                data-name="Grupo 1555"
                transform="translate(126.581 12.301)"
            >
                <g
                    id="Grupo_1554"
                    data-name="Grupo 1554"
                    clip-path="url(#clip-path-7)"
                >
                    <rect
                        id="Rectángulo_1305"
                        data-name="Rectángulo 1305"
                        width="13.419"
                        height="13.566"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g id="Grupo_1557" data-name="Grupo 1557" transform="translate(0)">
                <g
                    id="Grupo_1556"
                    data-name="Grupo 1556"
                    transform="translate(0)"
                    clip-path="url(#clip-path-8)"
                >
                    <rect
                        id="Rectángulo_1306"
                        data-name="Rectángulo 1306"
                        width="26.526"
                        height="27.579"
                        transform="translate(-1.639)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1559"
                data-name="Grupo 1559"
                transform="translate(22.582 3.369)"
            >
                <g
                    id="Grupo_1558"
                    data-name="Grupo 1558"
                    clip-path="url(#clip-path-9)"
                >
                    <rect
                        id="Rectángulo_1307"
                        data-name="Rectángulo 1307"
                        width="3.79"
                        height="4.204"
                        transform="translate(-0.009 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1561"
                data-name="Grupo 1561"
                transform="translate(23.952 4.584)"
            >
                <g
                    id="Grupo_1560"
                    data-name="Grupo 1560"
                    clip-path="url(#clip-path-10)"
                >
                    <rect
                        id="Rectángulo_1308"
                        data-name="Rectángulo 1308"
                        width="3.735"
                        height="4.216"
                        transform="translate(-0.009 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1563"
                data-name="Grupo 1563"
                transform="translate(5.721 5.974)"
            >
                <g
                    id="Grupo_1562"
                    data-name="Grupo 1562"
                    clip-path="url(#clip-path-11)"
                >
                    <rect
                        id="Rectángulo_1309"
                        data-name="Rectángulo 1309"
                        width="26.194"
                        height="30.244"
                        transform="translate(0 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1565"
                data-name="Grupo 1565"
                transform="translate(9.009 8.45)"
            >
                <g
                    id="Grupo_1564"
                    data-name="Grupo 1564"
                    transform="translate(0)"
                    clip-path="url(#clip-path-12)"
                >
                    <rect
                        id="Rectángulo_1310"
                        data-name="Rectángulo 1310"
                        width="2.098"
                        height="3.852"
                        transform="translate(-0.005 0)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
            <g
                id="Grupo_1567"
                data-name="Grupo 1567"
                transform="translate(20.841 20.633)"
            >
                <g
                    id="Grupo_1566"
                    data-name="Grupo 1566"
                    clip-path="url(#clip-path-13)"
                >
                    <rect
                        id="Rectángulo_1311"
                        data-name="Rectángulo 1311"
                        width="2.097"
                        height="3.852"
                        transform="translate(-0.005)"
                        fill="#6b24fa"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Verified;
